<script setup lang="ts" generic="TValue">
import { ref } from 'vue';

import { WsRadio } from '@mfl/common-components';
import { RadioGroupOption } from './radio-group-types';

const model = defineModel<TValue>({ required: true });

const props = defineProps<{
  aid: string;
  class?: string;
  options: Array<RadioGroupOption<TValue>>;
  confirm: (oldVal: TValue) => Promise<boolean | undefined>;
}>();

const localValue = ref<TValue>(model.value);

const handleChange = async () => {
  const confirmValueChange = await props.confirm(localValue.value);
  if (confirmValueChange === true) {
    model.value = localValue.value;
  } else {
    localValue.value = model.value;
  }
};
</script>
<template>
  <div class="radio-group-container" :class="props.class">
    <WsRadio
      v-for="option in props.options"
      :key="option.aid"
      v-model="localValue"
      :aid="option.aid"
      :value="option.value"
      :label="option.label"
      @update:model-value="handleChange"
    />
  </div>
</template>
<style>
.radio-group-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-start;
  padding-bottom: 14px;
}
</style>
