import { openDialog } from '@mfl/common-components';
import verificationDialog from './verification-dialog.vue';

import strings from '../../../../settings-strings';

export type verificationDialogInput = void;

export type verificationDialogOutput = void;

export const openVerificationDialog = async (
  input: verificationDialogInput
): Promise<verificationDialogOutput> => {
  return await openDialog<verificationDialogInput, verificationDialogOutput>({
    aid: 'EMAIL_VERIFICATION_DIALOG',
    headerText: strings.verificationDialogTitle,
    component: verificationDialog,
    input,
  });
};
