import { settingsManager } from '../settings-manager';
import { SyncTask } from '../settings-types';
import { useRequest } from './use-request';

const runSync = useRequest<SyncTask, Array<string>>(
  (unselectedSpecificators) => {
    return settingsManager.runSync(unselectedSpecificators);
  },
  {
    //TODO: make useRequest default values optional
    status: '',
    syncId: '',
  }
);

export default runSync;
