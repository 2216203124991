<script setup lang="ts">
import strings from '../../../settings-strings';

import { DomainSyncAndAutomationModel } from '../../../settings-types';

import WsCard from '../../../components/card/ws-card.vue';
import WsCardSection from '../../../components/card/ws-card-section.vue';
import WsCardSectionSeparator from '../../../components/card/ws-card-section-separator.vue';

import NewEmployeeStatusSelect from './controls/new-employee-status-select.vue';
import AutoInjectSelect from './controls/auto-inject-select.vue';
import DefaultGroupSelect from './controls/default-group-select.vue';
import AutoRemoveSelect from './controls/auto-remove-select.vue';
import NotifyEmployeeSelect from './controls/notify-employee-select.vue';
import { IntegrationType } from '@msl/settings-gateway-sdk';
import { computed, DeepReadonly } from 'vue';
import { EmployeeGroup } from '@msl/employee-group-gateway-sdk';

const model = defineModel<DomainSyncAndAutomationModel>({
  required: true,
});

const props = defineProps<{
  isIntegrated: boolean;
  integrationType: IntegrationType;
  groupsList: DeepReadonly<Array<EmployeeGroup>>;
}>();

const emit = defineEmits(['update:modelValue']);

const handleUpdateAutoActivate = (autoActivate: boolean) => {
  emit('update:modelValue', {
    ...model.value,
    newEmployees: {
      ...model.value.newEmployees,
      autoActivate,
    },
  });
};

const handleUpdateAutoInject = (autoInject: boolean) => {
  emit('update:modelValue', {
    ...model.value,
    employeeActivation: {
      ...model.value.employeeActivation,
      autoInject,
    },
  });
};

const handleUpdateDefaultGroup = (defaultGroup: string) => {
  emit('update:modelValue', {
    ...model.value,
    newEmployees: {
      ...model.value.newEmployees,
      defaultGroup,
    },
  });
};

const handleUpdateAutoDelete = (autoDelete: boolean) => {
  emit('update:modelValue', {
    ...model.value,
    sync: {
      ...model.value.sync,
      autoDelete,
    },
  });
};

const handleUpdateAutoInvite = (autoInvite: boolean) => {
  emit('update:modelValue', {
    ...model.value,
    employeeActivation: {
      ...model.value.employeeActivation,
      autoInvite,
    },
  });
};

const isIntegratedWithGoogle = computed(
  () => props.isIntegrated && props.integrationType === IntegrationType.GOOGLE
);
</script>

<template>
  <ws-card>
    <ws-card-section>
      <template #title>{{
        strings.automationsNewEmployeeStatusSectionTitle
      }}</template>
      <template #desc>{{
        strings.automationsNewEmployeeStatusSectionDescription
      }}</template>
      <template #control>
        <new-employee-status-select
          v-model="model.newEmployees.autoActivate"
          @update:model-value="handleUpdateAutoActivate"
        />
      </template>
    </ws-card-section>
    <template v-if="isIntegratedWithGoogle">
      <ws-card-section-separator />
      <ws-card-section>
        <template #title>{{
          strings.automationsAutoInjectSectionTitle
        }}</template>
        <template #desc>{{
          strings.automationsAutoInjectSectionDescription
        }}</template>
        <template #control>
          <auto-inject-select
            v-model="model.employeeActivation.autoInject"
            @update:model-value="handleUpdateAutoInject"
          />
        </template>
      </ws-card-section>
    </template>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{
        strings.automationsNewEmployeeGroupSectionTitle
      }}</template>
      <template #desc>{{
        strings.automationsNewEmployeeGroupSectionDescription
      }}</template>
      <template #control>
        <default-group-select
          v-model="model.newEmployees.defaultGroup"
          :groups-list="props.groupsList"
          @update:model-value="handleUpdateDefaultGroup"
        />
      </template>
    </ws-card-section>
    <template v-if="props.isIntegrated">
      <ws-card-section-separator />
      <ws-card-section>
        <template #title>{{
          strings.automationsAutoRemoveSectionTitle
        }}</template>
        <template #desc>{{
          strings.automationsEmployeeAutoRemoveSectionDescription
        }}</template>
        <template #control>
          <auto-remove-select
            v-model="model.sync.autoDelete"
            @update:model-value="handleUpdateAutoDelete"
          />
        </template>
      </ws-card-section>
    </template>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{
        strings.automationsEmployeeActivationNotificationSectionTitle
      }}</template>
      <template #desc>{{
        strings.automationsEmployeeActivationNotificationSectionDescription
      }}</template>
      <template #control>
        <notify-employee-select
          v-model="model.employeeActivation.autoInvite"
          @update:model-value="handleUpdateAutoInvite"
        />
      </template>
    </ws-card-section>
  </ws-card>
</template>
