<script setup lang="ts">
import strings from '../../../settings-strings';

import DeploymentInstructions from './controls/deployment-instructions.vue';
import InviteEmailControl from './controls/invite-email-control.vue';
import InviteEmailActions from './controls/invite-email-actions.vue';
import EmployeeEditPermissionsSelect from './controls/employee-edit-permissions-select.vue';

import WsCard from '../../../components/card/ws-card.vue';
import WsCardSection from '../../../components/card/ws-card-section.vue';
import WsCardSectionSeparator from '../../../components/card/ws-card-section-separator.vue';

import {
  EmployeePortalSettingsErrorsModel,
  EmployeePortalSettingsModel,
} from '../../../settings-types';
import { EmployeeEditPermissions } from '@msl/settings-gateway-sdk';

const model = defineModel<EmployeePortalSettingsModel>({
  required: true,
});

const props = defineProps<{
  domainId: string;
  adminEmployee?: {
    id: string;
    isActive: boolean;
    hasSignature: boolean;
  };
  errors: EmployeePortalSettingsErrorsModel;
}>();

const handleInstructionsChange = (values: Array<string>) => {
  model.value = {
    ...model.value,
    instructions: values,
  };
};

const handleEmailSubjectChange = (value: string) => {
  model.value = { ...model.value, emailSubject: value };
};

const handleinviteSenderChange = (value: string) => {
  model.value = { ...model.value, inviteSender: value };
};

const handleEmployeeEditPermissionsChange = (v: EmployeeEditPermissions) => {
  model.value = { ...model.value, employeeEditPermissions: v };
};
</script>
<template>
  <ws-card>
    <ws-card-section>
      <template #title>{{ strings.employeePortalInviteEmailTitle }}</template>
      <template #desc>
        <invite-email-actions
          :domain-id="props.domainId"
          :admin-employee="props.adminEmployee"
        />
      </template>
      <template #control>
        <invite-email-control
          :email-subject="model.emailSubject"
          :invite-sender="model.inviteSender"
          :errors="props.errors"
          @update:email-subject="handleEmailSubjectChange"
          @update:invite-sender="handleinviteSenderChange"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{ strings.employeeEditPermissions }}</template>
      <template #desc>
        {{ strings.employeeEditPermissionsDesc }}
      </template>
      <template #control>
        <EmployeeEditPermissionsSelect
          :model-value="model.employeeEditPermissions"
          @update:model-value="handleEmployeeEditPermissionsChange"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title
        >{{ strings.employeePortalDeploymentInstructionsTitle }}
      </template>
      <template #desc>{{
        strings.employeePortalDeploymentInstructionsDescr
      }}</template>
      <template #control>
        <deployment-instructions
          :model-value="model.instructions"
          @update:model-value="handleInstructionsChange"
        />
      </template>
    </ws-card-section>
  </ws-card>
</template>
