import { openDialog } from '@mfl/common-components';
import removeEmployeesDialog from './remove-employees-dialog.vue';

import strings from '../../../../settings-strings';

export type removeEmployeesDialogInput = string;

export type removeEmployeesDialogOutput = string;

export const openRemoveEmployeesDialog = async (
  input: removeEmployeesDialogInput
): Promise<removeEmployeesDialogOutput> => {
  return (
    (await openDialog<removeEmployeesDialogInput, removeEmployeesDialogOutput>({
      aid: 'REMOVE_EMPLOYEES_PROMPT',
      headerText: strings.removeEmployeesPromtTitle,
      component: removeEmployeesDialog,
      input,
    })) || ''
  );
};
