<script setup lang="ts">
import { computed, toValue } from 'vue';
import { WsButton } from '@mfl/common-components';

import strings from '../../settings-strings';

//TODO: this props type repeat ModelState type. Improve?
const props = defineProps<{
  aid: string;
  class?: string;
  isSaving: boolean;
  isSaved: boolean;
  hasChanges: boolean;
  // hasInvalidValues: boolean;
}>();

const emit = defineEmits(['save-changes']);

const text = computed<string>(() => {
  if (props.isSaved) {
    return strings.saved;
  }

  return strings.saveChanges;
});

const buttonClass = computed<string>(() => {
  return props.class || '';
});

const disabled = computed<boolean>(() => {
  return !props.hasChanges || props.isSaved;
});

const handleButtonClick = (event: MouseEvent) => {
  if (props.isSaving || toValue(disabled)) {
    event.preventDefault();
    return;
  }

  emit('save-changes');
};
</script>
<template>
  <WsButton
    :aid="props.aid"
    :class="['ws-save-button', buttonClass]"
    :label="text"
    :disabled="disabled"
    @click="handleButtonClick"
  />
</template>
<style scoped lang="scss">
button.this-will-be-fixed-in-scope-of-another-ticket {
  color: white;
  border-radius: 30px;
  background: var(--Primary, #1e90fa);
  color: #ffffff;

  height: 30px;
  padding: 8px 20px;
  gap: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;

  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &.saving {
    cursor: default;
  }

  &:disabled {
    color: var(--Gray-400, #828282);
    background-color: var(--Gray-200, #e0e0e0);
  }

  i {
    animation-duration: 1s;
    font-weight: normal;
  }
}
</style>
