<script setup lang="ts">
import { WsTooltip } from '@mfl/common-components';

const props = defineProps<{
  text: string;
  collapsed: boolean;
}>();
</script>
<template>
  <div class="side-menu-heading">
    <span class="side-menu-heading-text text-md font-normal">
      {{ props.collapsed ? '&nbsp;' : props.text }}
      <WsTooltip v-if="!props.collapsed" aid="side-menu-heading-tooltip">
        {{ props.text }}
      </WsTooltip>
    </span>
  </div>
</template>
<style lang="scss" scoped>
.side-menu-heading {
  display: block;
  padding: 14px;
  cursor: default;
  margin-bottom: -8px;
  .side-menu-heading-text {
    width: 100%;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
