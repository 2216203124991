<script setup lang="ts">
import strings from '../../../../settings-strings';
import { changeSpecificatorCategoryDialogInput } from '../change-specificator-category-dialog/change-specificator-category-dialog';
import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';

function confirmHandler() {
  dialog.close('remove');
}

const { dialog } = defineProps<{
  dialog: IDialog<changeSpecificatorCategoryDialogInput>;
}>();
</script>
<template>
  <ws-dialog-section>
    <p class="dialog-text">
      {{ strings.removeEmployeePromtQuestion.replace('-count-', dialog.input) }}
    </p>
  </ws-dialog-section>
  <ws-dialog-actions>
    <ws-button
      :label="strings.keepButtonTitle"
      aid="REMOVE_EMPLOYEES_PROMPTCANCEL_BUTTON"
      variant="outlined"
      color="gray-500"
      @click="dialog.close('keep')"
    />
    <ws-button
      :label="strings.removeEmployeesPromtButton"
      aid="REMOVE_EMPLOYEES_PROMPTCONFIRM_BUTTON"
      color="danger"
      @click="confirmHandler()"
    />
  </ws-dialog-actions>
</template>
<style scoped>
.dialog-text {
  color: #191c2b;
  font-family: Mulish;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  max-width: 402px;
}
</style>
