<script setup lang="ts"></script>
<template>
  <div class="ws-card-section-separator"></div>
</template>
<style lang="scss">
.ws-card-section-separator {
  box-sizing: border-box;
  border: none;
  max-height: 1px;
  height: 1px;
  background-color: #e0e0e0;
}
</style>
