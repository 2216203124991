<script setup lang="ts">
import { navigateTo } from '@mfl/framework';
import strings from '../../settings-strings';
import { getWebAppUrl } from '../../settings-utils';

const handleButtonClick = () => {
  navigateTo(getWebAppUrl(), false, true);
};
</script>

<template>
  <button
    class="settings-back-to-webapp-button"
    aid="BACK_TO_WEBAPP"
    @click="handleButtonClick"
  >
    <i class="fa-light fa-chevron-left"></i>
    <span class="back-button-label">{{ strings.back }}</span>
  </button>
</template>
<style lang="scss" scoped>
.settings-back-to-webapp-button {
  color: var(--Gray-500);
  cursor: pointer;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 6px;
  align-items: center;

  margin: 0;
  margin-left: 10px;
}
</style>
