import { isEmpty as _isEmpty } from 'lodash';
import { settingsManager } from '../../settings-manager';
import {
  DomainBasicInfoModel,
  DomainBasicInfoValidationErrorsModel,
} from '../../settings-types';
import { useSettingsModel } from '../../composables/use-settings-model';
import { validateDomainName } from '../../settings-utils';
import { IntegrationType } from '@msl/settings-gateway-sdk';

const domainBasicInfo = useSettingsModel<DomainBasicInfoModel>({
  getDefaultValues() {
    return {
      domain: '',
      integrationType: IntegrationType.OTHER,
      tz: '', //getCurrentTimezone(),
    };
  },

  async fetch() {
    return settingsManager.getBasicDomainInfo();
  },

  async update(data, oldData) {
    if (data.domain !== oldData.domain) {
      await settingsManager.updateDomainName({
        domainName: data.domain,
      });
    }
    if (
      data.tz !== oldData.tz ||
      data.integrationType !== oldData.integrationType
    ) {
      await settingsManager.updateBasicDomainInfo(data);
    }
  },

  validate(data) {
    const errors: DomainBasicInfoValidationErrorsModel = {};
    const domainInvalidMessage = validateDomainName(data.domain);
    if (domainInvalidMessage) {
      errors.domain = domainInvalidMessage;
    }

    return errors;
  },
});

export default {
  ...domainBasicInfo,
  async removeDomainIntegration() {
    try {
      await settingsManager.removeDomainIntegration();
    } catch (err) {
      domainBasicInfo.onError(err);
    }
  },
};
