<script setup lang="ts">
import { onMounted } from 'vue';

import strings from '../../settings-strings';

import WsSection from '../../components/section/ws-section.vue';
import SaveButton from '../../components/button/save-button.vue';
import DomainBasicInfo from './components/domain-basic-info.vue';
import { openRemoveIntegrationDialog } from './components/remove-integration-dialog/remove-integration-dialog';
import domainBasicInfo from './domain-basic-info';

import generalDomainInfo from '../../composables/domain-general-info';

const {
  state,
  validationErrors,
  onChange,
  triggerFetch,
  triggerUpdate,
  model,
} = domainBasicInfo;

const handleSaveChanges = async () => {
  //no need to refetch generalDomainInfo when failed to update;
  if (await triggerUpdate()) {
    //domain name/integration status may be changed so we need to refetch actual domain data
    await generalDomainInfo.triggerFetch();
  }
};

onMounted(() => {
  triggerFetch();
});
</script>
<template>
  <ws-section :is-loading="state.isLoading">
    <template #header>
      {{ strings.workspace }}
    </template>
    <template #body>
      <DomainBasicInfo
        :model-value="model"
        :domain-general-info="generalDomainInfo.data"
        :errors="validationErrors"
        @update:model-value="onChange"
        @remove-domain-integration="openRemoveIntegrationDialog()"
      />
    </template>
    <template #actions>
      <save-button
        aid="SAVE_BASIC_INFO_BUTTON"
        v-bind="state"
        @save-changes="handleSaveChanges"
      />
    </template>
  </ws-section>
</template>
