<script setup lang="ts">
import { computed } from 'vue';

import { WsTooltip, WsButton } from '@mfl/common-components';
import { openInviteEmailPreviewDialog } from '../invite-email-preview-dialog';

import { settingsManager } from '../../../../settings-manager';
import strings from '../../../../settings-strings';

const props = defineProps<{
  domainId: string;
  adminEmployee?: {
    id: string;
    isActive: boolean;
    hasSignature: boolean;
  };
}>();

const canSendTestEmail = computed(() => {
  return (
    props.adminEmployee?.id &&
    props.adminEmployee?.isActive &&
    props.adminEmployee?.hasSignature
  );
});

const sendTestEmail = async () => {
  if (!canSendTestEmail.value) {
    return;
  }

  await settingsManager.sendTestInviteEmail({
    employeeId: props.adminEmployee!.id,
  });
};

const handlePreviewInviteEmailClik = () => {
  openInviteEmailPreviewDialog({
    domainId: props.domainId,
  });
};

const sendTestEmailTooltip = computed(() => {
  return props.adminEmployee?.id
    ? strings.employeePortalInviteEmailSendYourselfTestEmailIsNotEmployeeTooltip
    : strings.employeePortalInviteEmailSendYourselfTestEmailIsInactiveTooltip;
});
</script>
<template>
  <div class="invite-email-actions">
    <div class="invite-email-desc">
      {{ strings.employeePortalInviteEmailDescr }}
    </div>
    <div class="invite-email-action-links">
      <ws-button
        aid="INVITE_EMAIL_PREVIEW_LINK"
        :label="strings.employeePortalInviteEmailPreviewEmailLink"
        size="sm"
        variant="text"
        @click="handlePreviewInviteEmailClik()"
      />
      <ws-button
        aid="SEND_TEST_EMAIL_LINK"
        size="sm"
        :disabled="!canSendTestEmail"
        variant="text"
        @click="sendTestEmail()"
      >
        {{ strings.employeePortalInviteEmailSendYourselfTestEmail }}
        <ws-tooltip v-if="!canSendTestEmail" aid="SEND_YOURSELF_TEST_EMAIL">
          {{ sendTestEmailTooltip }}
        </ws-tooltip>
      </ws-button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.invite-email-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  .invite-email-desc {
  }

  .invite-email-action-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: flex-start;
    gap: 6px;
  }
}
</style>
