import { openDialog } from '@mfl/common-components';
import changeSpecificatorCategoryDialog from './change-specificator-category-dialog.vue';

import strings from '../../../../settings-strings';

export type changeSpecificatorCategoryDialogInput = string;

export type changeSpecificatorCategoryDialogOutput = boolean;

export const openChangeSpecificatorCategoryDialog = async (
  input: changeSpecificatorCategoryDialogInput
): Promise<changeSpecificatorCategoryDialogOutput> => {
  return (
    (await openDialog<
      changeSpecificatorCategoryDialogInput,
      changeSpecificatorCategoryDialogOutput
    >({
      aid: 'CHANGE_SPECIFICATOR_TYPE_DIALOG',
      headerText: strings.departmentsDialogTitle.replace(
        '-specificatorTypeTitle-',
        input
      ),
      component: changeSpecificatorCategoryDialog,
      input,
    })) || false
  );
};
