import { openDialog } from '@mfl/common-components';
import InviteEmailPreviewDialog from './invite-email-preview-dialog.vue';

import strings from '../../../settings-strings';

export type InviteEmailPreviewDialogInput = {
  domainId: string;
};

export type InviteEmailPreviewDialogOutput = void;

export const openInviteEmailPreviewDialog = async (
  input: InviteEmailPreviewDialogInput
): Promise<InviteEmailPreviewDialogOutput> => {
  await openDialog<
    InviteEmailPreviewDialogInput,
    InviteEmailPreviewDialogOutput
  >({
    aid: 'INVITE_EMAIL_PREVIEW_DIALOG',
    headerText: strings.employeePortalInviteEmailPreviewDialogHeader,
    component: InviteEmailPreviewDialog,
    input,
  });
};
