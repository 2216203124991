<script setup lang="ts">
import { computed, toValue } from 'vue';
import { SortDirection } from './filtered-list-types';
import { WsIconButton } from '@mfl/common-components';

const model = defineModel<SortDirection>({ required: true });

const sortAsc = computed<boolean>({
  get() {
    return toValue(model) === SortDirection.Asc;
  },
  set(v: boolean) {
    model.value = v ? SortDirection.Asc : SortDirection.Desc;
  },
});

const toggleSortDirection = () => {
  sortAsc.value = !sortAsc.value;
};

const props = defineProps<{
  aid: string;
}>();
</script>
<template>
  <WsIconButton
    variant="text"
    color="gray-400"
    size="md"
    :aid="props.aid"
    @click="toggleSortDirection"
  >
    <i
      class="fa-regular text-lg"
      :class="{
        'fa-arrow-down-a-z': sortAsc,
        'fa-arrow-up-a-z': !sortAsc,
      }"
    ></i>
  </WsIconButton>
</template>
