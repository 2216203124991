import { settingsManager } from '../settings-manager';
import { useRequest } from './use-request';

const getInvitationTrustPolicy = useRequest(
  () => {
    return settingsManager.getInvitationTrustPolicy();
  },
  {
    inviteEmployeesBlocked: false,
  }
);
export default getInvitationTrustPolicy;
