<script setup lang="ts">
import { mapValues as _mapValues } from 'lodash';
import {
  AnalyticsFieldName,
  AnalyticsSettingsModel,
} from '../../../../settings-types';
import AnalyticsFieldSelect from './analytics-field-select.vue';
import { Ref } from 'vue';

const props = defineProps<{
  fieldsData: Array<{
    name: AnalyticsFieldName;
    placeholder: string;
    label: string;
    toggleLabel: string;
    toggleAid: string;
    inputAid: string;
    selectAid: string;
    tooltip?: string;
    options: Ref<
      Array<{
        header: string;
        options: Array<{
          value: string;
          label: string;
        }>;
      }>
    >;
  }>;
}>();

const model = defineModel<AnalyticsSettingsModel>({
  required: true,
});

const handleAnalyticsFieldChange = (
  name: AnalyticsFieldName,
  value: string
) => {
  model.value = {
    ...model.value,
    [name]: value,
  };
};
</script>
<template>
  <div class="analytics-fields">
    <div
      v-for="field in props.fieldsData"
      :key="field.name"
      class="analytic-field-wrap"
    >
      <analytics-field-select
        :model-value="model[field.name]"
        :placeholder="field.placeholder"
        :name="field.name"
        :tooltip="field.tooltip"
        :label="field.label"
        :options="field.options.value"
        :toggle-aid="field.toggleAid"
        :input-aid="field.inputAid"
        :select-aid="field.selectAid"
        @update:model-value="handleAnalyticsFieldChange(field.name, $event)"
      />
    </div>
  </div>
</template>
