<script setup lang="ts">
import { WsSelect } from '@mfl/common-components';
import strings from '../../../../settings-strings';
import { UserEntitlements } from '../../../../settings-types';
import { currentUser } from '@mfl/platform-shell';
import { getWebAppUrl } from '../../../../settings-utils';
import SelectOptionWithUpgradable from '../../../../components/select/select-option-with-upgradable.vue';
import { EmployeeEditPermissions } from '@msl/settings-gateway-sdk';

const model = defineModel<EmployeeEditPermissions>();

const options: Array<{
  label: string;
  value: EmployeeEditPermissions;
  upgradeRequired?: boolean;
  aid: string;
}> = [
  {
    label: strings.employeeEditPermissionsOptionAll,
    value: EmployeeEditPermissions.ALL,
    upgradeRequired: !currentUser.isEntitledTo(
      UserEntitlements.EMPLOYEE_EDIT_PERMISSIONS
    ),
    aid: 'EMPLOYEE_EDIT_PERMISSION_OPTION_ALL',
  },
  {
    label: strings.employeeEditPermissionsOptionCustom,
    value: EmployeeEditPermissions.CUSTOM,
    upgradeRequired: !currentUser.isEntitledTo(
      UserEntitlements.EMPLOYEE_EDIT_PERMISSIONS
    ),
    aid: 'EMPLOYEE_EDIT_PERMISSION_OPTION_CUSTOM',
  },
  {
    label: strings.employeeEditPermissionsOptionNone,
    value: EmployeeEditPermissions.NONE,
    aid: 'EMPLOYEE_EDIT_PERMISSION_OPTION_NONE',
  },
];

const labels: Record<EmployeeEditPermissions, string> = options.reduce(
  (acc, o) => {
    acc[o.value] = o.label;
    return acc;
  },
  {} as Record<EmployeeEditPermissions, string>
);

const handleUpdateModelValue = (v: unknown) => {
  const selected = options.find((o) => o.value === v);
  if (selected?.upgradeRequired) {
    return window.open(getWebAppUrl() + '#upgrade', '_blank');
  }
  model.value = v as EmployeeEditPermissions;
};

const optionLabelFn = (v: EmployeeEditPermissions) => labels[v];
const optionKeyFn = (v: EmployeeEditPermissions) => v;
</script>
<template>
  <WsSelect
    :option-label="optionLabelFn"
    :option-key="optionKeyFn"
    :model-value="model"
    aid="EMPLOYEE_EDIT_PERMISSIONS_SELECT"
    @update:model-value="handleUpdateModelValue"
  >
    <SelectOptionWithUpgradable
      v-for="o in options"
      :key="o.value"
      :aid="o.aid"
      :value="o.value"
      :label="o.label"
      :upgrade-required="o.upgradeRequired"
    />
  </WsSelect>
</template>
<style lang="scss"></style>
