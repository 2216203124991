import type { Directive } from 'vue';
const loadingDirective: Directive<HTMLElement, boolean> = function (
  el,
  binding
) {
  const isFrameworkLoaderIsVisible = (): boolean => {
    const frameloader = document.getElementById('frame-loader');
    return !!frameloader && frameloader.checkVisibility();
  };

  const runDirective = async () => {
    if (binding.value) {
      // this is a temporary solution
      if (isFrameworkLoaderIsVisible()) {
        return;
      }
      el.classList.add('state-loading');
      //do we really need this disabled?
      // el.setAttribute('disabled', 'disabled');
    } else {
      el.classList.remove('state-loading');
      // el.removeAttribute('disabled');
    }
  };

  runDirective();
};

export default loadingDirective;
