import { reactive, ref, Ref, shallowReadonly, toValue } from 'vue';

interface IRequestManager<TData, TParams = undefined> {
  fetch: (params?: TParams) => Promise<TData>;
  getDefaultData?: () => TData;
  done: (data: TData) => boolean;
}

type UseIntervalRequest<TData, TParams = undefined> = Readonly<{
  data: TData;
  triggerFetch: (params?: TParams) => Promise<void>;
  stop: () => void;

  isLoading: boolean;
  error: string | undefined;
}>;

type UseRequestOptions = {
  delay: number;
  silentFetch: boolean;
  silentRefresh: boolean;
};

const defaultUseRequestOptions: UseRequestOptions = {
  delay: 3500,
  silentFetch: false,
  silentRefresh: true,
};

export const useIntervalRequest = <TData, TParams = undefined>(
  manager: IRequestManager<TData, TParams>,
  options: UseRequestOptions = defaultUseRequestOptions
): UseIntervalRequest<TData> => {
  let forceStop = false;

  const data = ref<TData | undefined>(
    manager.getDefaultData ? manager.getDefaultData() : undefined
  );
  const error = ref<string | undefined>();
  const isLoading = ref<boolean>(false);

  const keepGoing = () => {
    return !forceStop && !manager.done(toValue(data));
  };

  const triggerFetch = async (params?: TParams) => {
    isLoading.value = options.silentFetch ? false : true;
    try {
      data.value = await manager.fetch(params);
      setTimeout(async () => {
        if (keepGoing()) {
          await triggerFetch();
        }
      }, options.delay);
    } catch (err) {
      console.error('use-interval-request.triggerFetch error: ', err);
      //todo: reowrk this to show beautifull message
      error.value = `${err}`;
    } finally {
      isLoading.value = false;
    }
  };

  const stop = () => {
    forceStop = true;
  };

  return shallowReadonly(
    reactive({
      data: data as Ref<TData>,
      isLoading,
      error,
      triggerFetch,
      stop,
    })
  );
};
