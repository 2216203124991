<script setup lang="ts">
import { onMounted } from 'vue';
import strings from '../../settings-strings';

import WsSection from '../../components/section/ws-section.vue';
import SaveButton from '../../components/button/save-button.vue';

import AnalyticsSettings from './components/analytics-settings.vue';

import generalDomainInfo from '../../composables/domain-general-info';

import domainAnalytics from './domain-analytics';
import { AnalyticsSettingsModel } from '../../settings-types';

const { state, onChange, triggerFetch, triggerUpdate, model } = domainAnalytics;

const handleSaveChanges = () => {
  triggerUpdate();
};

const handleUpdateAnalyticsSettings = (v: AnalyticsSettingsModel) => {
  onChange(v);
};

onMounted(() => {
  triggerFetch();
});
</script>
<template>
  <ws-section :is-loading="state.isLoading">
    <template #header>
      {{ strings.analytics }}
    </template>
    <template #body>
      <analytics-settings
        :model-value="model"
        :is-loading="state.isLoading"
        :domain-data="generalDomainInfo.data.domainData"
        @update:model-value="handleUpdateAnalyticsSettings"
      />
    </template>
    <template #actions>
      <save-button
        aid="SAVE_ANALYTICS_BUTTON"
        v-bind="state"
        @save-changes="handleSaveChanges"
      />
    </template>
  </ws-section>
</template>
<style></style>
