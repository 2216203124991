import { settingsManager } from '../settings-manager';
import { useRequest } from './use-request';

const emailVerificationModel = useRequest(
  () => {
    return settingsManager.sendVerificationEmail();
  },
  {
    status: '',
  }
);

export default emailVerificationModel;
