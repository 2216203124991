import { currentUser } from '@mfl/platform-shell';

import { groupsManager } from '../groups-manager';
import { useRequest } from '../composables/use-request';

const defaultValues = [
  {
    groupId: '',
    name: '',
    isPlaceholder: false,
    isDefault: false,
    signatureId: '',
    replySignatureId: '',
    forwardSignatureId: '',
  },
];

const domainGroups = useRequest(async () => {
  return await groupsManager.getGroupList(currentUser.domainId);
}, defaultValues);

export default domainGroups;

// export const domainGroupsModel = ref<DomainGroup[]>([]);

// export const isLoading = ref<boolean>(false);
// export const error = ref<string>('');

// export const triggerDomainGroupsFetch = async () => {
//   isLoading.value = true;
//   try {
//     const domainGroups = await groupsManager.getGroupList(currentUser.domainId);
//     domainGroupsModel.value = domainGroups;
//   } catch (err) {
//     console.log('triggerGroupsList error: ', err);
//   } finally {
//     isLoading.value = false;
//   }
// };
