<script setup lang="ts">
import { WsTooltip } from '@mfl/common-components';

const props = withDefaults(
  defineProps<{
    tooltip: string;
    tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
    class?: string;
    aid: string;
  }>(),
  {
    class: 'tooltip-icon-trigger fa-light fa-circle-info',
    tooltipPosition: 'top',
  }
);
</script>
<template>
  <i :class="props.class">
    <ws-tooltip :aid="props.aid" :position="tooltipPosition">
      {{ props.tooltip }}
    </ws-tooltip>
  </i>
</template>
<style lang="scss" scoped>
.tooltip-icon-trigger {
  //tooltip trigger icon size
  font-size: 14px;
}
</style>
