<script setup lang="ts">
import strings from '../../../../settings-strings';
import { changeSpecificatorCategoryDialogInput } from './change-specificator-category-dialog';
import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';

function confirmHandler() {
  dialog.close(true);
}

const { dialog } = defineProps<{
  dialog: IDialog<changeSpecificatorCategoryDialogInput>;
}>();
</script>
<template>
  <ws-dialog-section>
    <p class="dialog-text">
      {{
        strings.changeSpecificatorTypeConfirmQuestion.replace(
          '-category-',
          dialog.input
        )
      }}
    </p>
  </ws-dialog-section>
  <ws-dialog-actions>
    <ws-button
      :label="strings.EditDomainNameDialogCancelButton"
      aid="CHANGE_SPECIFICATOR_CATEGORY_DIALOG_CANCEL_BUTTON"
      variant="outlined"
      color="gray-500"
      @click="dialog.close()"
    />
    <ws-button
      :label="strings.changeSpecificatorTypeConfirmButtonTitle"
      aid="CHANGE_SPECIFICATOR_CATEGORY_DIALOG_CONFIRM_BUTTON"
      color="primary"
      @click="confirmHandler()"
    />
  </ws-dialog-actions>
</template>
<style scoped>
.dialog-text {
  color: #191c2b;
  font-family: Mulish;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  max-width: 402px;
}
</style>
