<script setup lang="ts">
import SelectWithSearch from '../../../../components/select/select-with-search.vue';
import {
  getAllTimezoneValues,
  getTimezoneWithGMTOffset,
} from '../../../../settings-utils';

const model = defineModel<string>({ required: true });

const tzValues = getAllTimezoneValues();

const tzOptions = tzValues.map((tz) => ({
  text: getTimezoneWithGMTOffset(tz),
  value: tz,
}));
</script>
<template>
  <div class="timezone-control">
    <select-with-search
      v-model="model"
      aid="TIME_ZONE_SELECT"
      :options="tzOptions"
    />
  </div>
</template>
