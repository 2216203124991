<script setup lang="ts">
import { WsTooltip } from '@mfl/common-components';

import { computed } from 'vue';
import { DepartmentsDialogOption, PathPart } from './departments-dialog';
import strings from '../../../../settings-strings';

const props = defineProps<DepartmentsDialogOption>();

function preparePartsForOrgUnit(name: string): PathPart {
  if (name === '/') {
    return {
      text: 'Top-level OU',
    };
  }

  const pathParts = name.split('/');

  pathParts[0] = pathParts[0] || 'Top-level OU';

  const len = pathParts.length;
  if (len > 4) {
    return {
      text: `${pathParts[0]}/.../${pathParts[len - 2]}/${pathParts[len - 1]}`,
      dataValue: name,
    };
  }

  if (len === 4) {
    return {
      text: `${pathParts[0]}/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`,
      dataValue: `${name}`,
    };
  }

  return {
    text: pathParts.join('/'),
  };
}

const departmentPathParts = computed<Array<PathPart>>(() => {
  const parts: Array<PathPart> = [];

  if (props.data.isNone) {
    parts.push({
      text: strings.employeesWithNoSomething.replace(
        '{{something}}',
        props.data.title || ''
      ),
    });
  } else if (props.data.isOrgUnit) {
    parts.push(preparePartsForOrgUnit(props.data.name || ''));
  } else {
    parts.push({
      text: props.data.name || '',
    });
  }

  parts.push({
    text: `(${props.data.userCount} employees)`,
    small: true,
  });

  return parts;
});
</script>
<template>
  <div class="department-item">
    <template v-for="part in departmentPathParts" :key="part.text">
      <span
        :class="{
          'font-medium': true,
          'text-xs': part.small,
          'path-part': true,
        }"
        >{{ part.text }}</span
      >
      <WsTooltip v-if="part.dataValue" :aid="part.dataValue" size="md">
        <div class="tooltip-content">{{ part.dataValue }}</div>
      </WsTooltip>
    </template>
  </div>
</template>
<style lang="scss">
.department-item {
  display: flex;
  justify-content: space-between;
  // width: 100%;

  .path-part {
    display: inline-block;
    margin: 0;
    padding: 0;

    &:first-child {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 390px;
      white-space: nowrap;
    }
  }
}
</style>
