<script setup lang="ts">
import { currentUser } from '@mfl/platform-shell';
import { UserEntitlements } from '../../../../settings-types';

import BoolSelect from '../../../../components/select/bool-select.vue';

import strings from '../../../../settings-strings';
import { getWebAppUrl } from '../../../../settings-utils';
import { BoolSelectUpgradabeOptions } from '../../../../components/select/select-types';
import { computed, toValue } from 'vue';

const model = defineModel<boolean>({ required: true });

const upgradePlanRequired = computed(
  () => !currentUser.isEntitledTo(UserEntitlements.SETTINGS_AUTOMATIONS)
);

const options = computed<BoolSelectUpgradabeOptions>(() => [
  {
    value: 'off',
    label: strings.doNotSyncWith,
  },
  {
    value: 'on',
    label: strings.syncWith,
    requiresUpgrade: toValue(upgradePlanRequired),
  },
]);

const handleUpgradePlan = () => {
  if (toValue(upgradePlanRequired)) {
    window.open(getWebAppUrl() + '#upgrade', '_blank');
  }
};

const handleUpdate = (val: unknown) => {
  model.value = !!val;
};
</script>

<template>
  <BoolSelect
    :model-value="model"
    :options="options"
    aid="SYNC_DISABLED_SELECT"
    @update:model-value="handleUpdate"
    @upgrade-plan="handleUpgradePlan"
  />
</template>
