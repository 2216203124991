export type FilteredListItem<TData> = {
  aid: string;
  searchKey: string;
  data: TData;
  selected: boolean;
  //not the best name, this item go first in the list ignoring sort order
  special?: boolean;
};

export enum SelectAllState {
  All = 1,
  None = 2,
  Some = 3,
}

export enum SortDirection {
  None = 'none',
  Asc = 'asc',
  Desc = 'desc',
}
