<script setup lang="ts">
import BoolSelect from '../../../../components/select/bool-select.vue';
import { BoolSelectOptions } from '../../../../components/select/select-types';
import strings from '../../../../settings-strings';

const model = defineModel<boolean>({ required: true });

const autoInjectOptions: BoolSelectOptions = [
  {
    value: 'on',
    label: strings.on,
  },
  {
    value: 'off',
    label: strings.off,
  },
];
</script>

<template>
  <BoolSelect
    v-model="model"
    aid="AUTO_INJECT_SELECT"
    :options="autoInjectOptions"
  />
</template>
