<script setup lang="ts">
import BoolSelect from '../../../../components/select/bool-select.vue';
import { BoolSelectOptions } from '../../../../components/select/select-types';
import { openVerificationDialog } from '../verification-dialog/verification-dialog';
import sendVerificationEmailModel from '../../../../composables/send-verification-email-model';
import getInvitationTrustPolicyModel from '../../../../composables/invitation-trust-policy-model';

import strings from '../../../../settings-strings';

const model = defineModel<boolean>({ required: true });

const options: BoolSelectOptions = [
  {
    value: 'on',
    label: strings.notifyEmployee,
  },
  {
    value: 'off',
    label: strings.dontNotifyEmployee,
  },
];

const handleUpdate = async (value: unknown) => {
  const val = !!value;
  if (val && getInvitationTrustPolicyModel.data.inviteEmployeesBlocked) {
    await sendVerificationEmailModel.triggerFetch();
    openVerificationDialog();
  } else {
    model.value = val;
  }
};
</script>

<template>
  <BoolSelect
    :model-value="model"
    :options="options"
    aid="NOTIFY_EMPLOYEE_SELECT"
    @update:model-value="handleUpdate"
  />
</template>
