<script setup lang="ts">
import { toValue } from 'vue';
import { openSetDefaultActiveStatusDialog } from '../set-default-active-status-dialog/set-default-active-status-dialog';

import strings from '../../../../settings-strings';

import BoolSelect from '../../../../components/select/bool-select.vue';
import { BoolSelectUpgradabeOptions } from '../../../../components/select/select-types';

const model = defineModel<boolean>({ required: true });

const emailServiceOptions: BoolSelectUpgradabeOptions = [
  {
    value: 'on',
    label: strings.active,
  },
  {
    value: 'off',
    label: strings.inactive,
  },
];

async function handleUpdate(v: unknown) {
  const val = !!v;
  const setAsActive = val ? await openSetDefaultActiveStatusDialog() : val;
  model.value = setAsActive;
}
</script>

<template>
  <BoolSelect
    :model-value="model"
    aid="NEW_EMPLOYEE_STATUS_SELECT"
    :options="toValue(emailServiceOptions)"
    @update:model-value="handleUpdate"
  />
</template>
