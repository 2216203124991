import { computed, reactive, readonly, ref, toValue, watch } from 'vue';
import { preventPageUnload, releasePageUnload } from '@mfl/framework';
import { toast, ToastStatus } from '@mfl/common-components';

import type { Ref } from 'vue';

import {
  isEqual as _isEqual,
  cloneDeep as _cloneDeep,
  set as _set,
  get as _get,
} from 'lodash';
import strings from '../settings-strings';
import { createDebouncedHandler } from '../settings-utils';
import { ValidationErrorsModel } from '../settings-types';

import { IModelManager, ModelComposable } from './use-settings-model.types';

//TODO: disable save button right nefore trigger validation, we must ensure validation before save
export const useSettingsModel = <TModel extends object>(
  manager: IModelManager<TModel>
): ModelComposable<TModel> => {
  const originalData = ref<TModel>(manager.getDefaultValues());
  const model = ref<TModel>(manager.getDefaultValues());

  const validationErrors = ref<ValidationErrorsModel<TModel>>(
    {} as ValidationErrorsModel<TModel>
  );

  const isLoading = ref<boolean>(false);
  const isSaving = ref<boolean>(false);
  const isSaved = ref<boolean>(false);

  const hasChanges = computed<boolean>(() => {
    return !_isEqual(toValue(originalData), toValue(model));
  });

  const hasInvalidValues = computed<boolean>(() => {
    const errors = toValue(validationErrors);
    return Object.keys(errors).length > 0;
  });

  const error = ref<string | undefined>(undefined);

  watch(
    () => toValue(hasChanges),
    (changed) => {
      if (changed) {
        preventPageUnload();
      } else {
        releasePageUnload();
      }
    }
  );

  const handleError = (err: unknown, msg?: string) => {
    if (err) {
      console.error('use-settings-model.handleError', err);
    }

    error.value = msg || `${err}`;

    toast({
      status: ToastStatus.Error,
      message: error.value,
      aid: 'SETTINGS_ERROR_TOAST',
    });
  };

  const triggerFetch = async () => {
    isLoading.value = true;
    try {
      const modelData = await manager.fetch();
      model.value = modelData;

      originalData.value = _cloneDeep(modelData);
      return true;
    } catch (err) {
      handleError(err);
    } finally {
      isLoading.value = false;
    }
    return false;
  };

  const triggerUpdate = async () => {
    if (toValue(hasInvalidValues)) {
      handleError(undefined, strings.changesWerentSaved);
      return false;
    }

    if (toValue(isSaving)) {
      return false;
    }

    isSaving.value = true;
    try {
      const v = toValue(model);
      const prevData = toValue(originalData);

      await manager.update(v, prevData);

      isSaved.value = true;
      originalData.value = _cloneDeep(v);
      model.value = _cloneDeep(v);

      return true;
    } catch (err) {
      handleError(err, strings.failedToSaveChanges);
    } finally {
      isSaving.value = false;
    }

    return false;
  };

  const triggerPartlyUpdate = async (fields: string[]) => {
    if (toValue(hasInvalidValues)) {
      handleError(undefined, strings.changesWerentSaved);
      return false;
    }

    if (toValue(isSaving)) {
      return false;
    }

    isSaving.value = true;
    try {
      const v = toValue(model);
      const prevData = toValue(originalData);
      const prevDataUpd = _cloneDeep(prevData);
      fields.forEach((f) => {
        _set(prevDataUpd, f, _get(v, f));
      });
      await manager.update(prevDataUpd, prevData);
      originalData.value = _cloneDeep(prevDataUpd);
      model.value = _cloneDeep(v);
      isSaved.value = !toValue(hasChanges);
      return true;
    } catch (err) {
      handleError(err, strings.failedToSaveChanges);
    } finally {
      isSaving.value = false;
    }

    return false;
  };

  const debouncedValidator = createDebouncedHandler((val: TModel) => {
    if (!toValue(hasChanges)) {
      return;
    }
    validationErrors.value = manager.validate(val);
  }, 300);

  const onChange = (v: TModel) => {
    model.value = v;
    isSaved.value = false;
    validationErrors.value = {};

    debouncedValidator(toValue(model));
  };

  const onError = (err: unknown) => {
    handleError(err);
  };

  return {
    model: model as Ref<TModel>,
    validationErrors: validationErrors as Ref<ValidationErrorsModel<TModel>>,

    state: readonly(
      reactive({
        isLoading,
        isSaving,
        isSaved,
        error,
        hasChanges,
        hasInvalidValues,
      })
    ),

    onChange,
    onError,
    triggerPartlyUpdate,
    triggerFetch,
    triggerUpdate,
  };
};
