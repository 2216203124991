export default {
  workspace: 'Organization',
  domain: 'Domain',
  edit: 'Edit',
  back: 'Back',
  manage: 'Manage',
  remove: 'Remove',
  syncAndAutomations: 'Sync & Automations',
  automations: 'Automations',
  sync: 'Sync',
  employeePortal: 'Employee hub',
  analytics: 'Analytics',
  emailService: 'Email service',
  timeZone: 'Time zone',
  EditDomainNameDialogTitle: 'Edit domain name',
  EditDomainNameDialogConfirmButton: 'Save domain',
  EditDomainNameDialogCancelButton: 'Cancel',
  EditDomainNameDialogInputLabel: 'Domain name',
  EmailServiceConnectGoogleButton: 'Connect to Google Workspace',
  EmailServiceConnectmicrosoftButton: 'Connect to Microsoft Entra',
  EmailServiceConnectButtonDescription:
    'Easily import all users from your directory and keep your data in sync automatically',
  sectionAHeader: 'section A',
  sectionBHeader: 'section B',
  sectionCHeader: 'section C',
  domainUrl: 'Domain',
  employeePortalDeploymentInstructionsTitle: 'Deployment instructions',
  employeePortalDeploymentInstructionsDescr:
    'Select which deployment instructions should appear in the employee hub. These instructions guide employees on how to set up their signatures on various platforms. This setting is especially useful if your organization lacks a centralized deployment option and additional guidance is needed for certain platforms.',
  employeePortalInviteEmailTitle: 'Invite email',
  employeePortalInviteEmailDescr:
    'Customize the email sent to employees. It informs them about their signature status and provides access to the employee hub, where they can review details and follow instructions to deploy their signature.',
  employeePortalInviteEmailSubjectLineLabel: 'Subject line',
  employeePortalInviteEmailSenderNameLabel: 'Sender name',
  employeePortalInviteEmailPreviewEmailLink: 'Preview email',
  employeePortalInviteEmailSendYourselfTestEmail: 'Send yourself a test email',
  employeePortalInviteEmailSubjectIsRequiredErrorMsg:
    'Email Subject is required',
  employeePortalInviteEmailSubjectContainsUrlErrorMsg:
    'Email Subject cannot contain urls',
  employeePortalInviteEmailSubjectTooLongErrorMsg:
    'Email Subject cannot be more than {maxLength} chars',
  employeePortalInviteEmailSenderNameIsRequiredErrorMsg:
    'Sender Name is required',
  employeePortalInviteEmailSenderNameContainsUrlErrorMsg:
    'Sender Name cannot contain urls',
  employeePortalInviteSenderNameTooLongErrorMsg:
    'Sender Name  cannot be more than {maxLength} chars',
  employeePortalInviteEmailSendYourselfTestEmailIsInactiveTooltip:
    'Employee is inactive',
  employeePortalInviteEmailSendYourselfTestEmailIsNotEmployeeTooltip:
    'Create an active employee for yourself',
  employeePortalInviteEmailPreviewDialogHeader: 'Invite email preview',
  employeePortalInviteEmailPreviewDialogButton: 'Got it',
  domainGeneralDescr:
    'Enter your domain to identify the specific domain you are managing. This allows WiseStamp to apply the correct configurations and manage your account effectively.',
  domainEmailServiceDescr:
    'Select your email service provider to customize your experience on the WiseStamp platform',
  domainTimeZoneDescr:
    'Set your organization’s time zone to align campaign activities with your local time. This ensures that campaign banners are disaplyed at the proper time.',
  backToDashboard: 'Back to dashboard',
  domainNotEditable:
    'Integrated domains can not be updated. Remove the integration to edit the domain url',
  confirmRemoveIntegrationPromptTitle: 'Confirm integration removal',
  confirmRemoveIntegrationPromptText:
    'Removing this integration will stop automatic updates to your employee data and prevent the upload of employee signatures.',
  confirmRemoveIntegrationPromptQuestion:
    'Are you sure you want to remove the integration?',
  confirmRemoveIntegrationPromptOkButton: 'Remove integration',
  confirmRemoveIntegrationPromptCancelButton: 'Cancel',
  requiredField: 'Required field',
  validUrlError: 'Please enter a valid URL',
  loading: 'Loading...',
  analyticsGeneralTitle: 'UTM Parameters',
  analyticsGeneralDescription:
    'Add UTM parameters to links in your email signatures to track website traffic. Set the UTM campaign, source, and medium. Use free text for static tags or variables for dynamic values.',
  analyticsGeneralCompanyFieldSection: 'Company variables',
  analyticsGeneralEmployeeFieldSection: 'Employee variables',
  analyticsGeneralCompanyFieldCampaignNameLabel: 'Campaign name',
  analyticsGeneralCompanyFieldCampaignMediumLabel: 'Campaign medium',
  analyticsGeneralCompanyFieldCampaignSourceLabel: 'Campaign source',
  analyticsGeneralCompanyFieldToggleLabel: 'Use variable',
  automationsNewEmployeeStatusSectionTitle: 'New employee initial status',
  automationsNewEmployeeStatusSectionDescription:
    'Set the initial status for new employees. This determines whether employees are immediately activated or remain inactive until manually updated.',
  automationsAutoInjectSectionTitle: 'Inject to Gmail on activation',
  automationsAutoInjectSectionDescription:
    'Set whether employee signatures are automatically injected into Gmail upon employee activation.',
  automationsNewEmployeeGroupSectionTitle: 'Default group assignment',
  automationsNewEmployeeGroupSectionDescription:
    'Set a default group for employees. Synced employees inherit this only if the corresponding field in the directory is blank.',
  automationsAutoRemoveSectionTitle: 'Auto remove employees',
  automationsEmployeeAutoRemoveSectionDescription:
    'Set whether to automatically remove employees from WiseStamp when they are deleted from your directory. ',
  automationsEmployeeActivationNotificationSectionTitle:
    'Send invite email on activation',
  automationsEmployeeActivationNotificationSectionDescription:
    'Set whether to send an invite email upon employee activation.',
  editCompanyVariable: 'Edit company variable',
  utmSource: 'UTM source',
  utmMedium: 'UTM medium',
  utmCampaign: 'UTM campaign',
  save: 'Save',
  saved: 'Saved!',
  saveChanges: 'Save changes',
  select: 'Select',
  syncDailySectionTitle: 'Daily sync',
  syncDailySectiondescription:
    'Set whether to sync WiseStamp with your directory daily. Running daily syncs ensures that your employee data and signatures stay up to date.',
  syncTypeSectionTitle: 'Synced employees',
  syncTypeSectionDescription:
    'Set whether to sync all users from your directory to WiseStamp or only those who meet specific conditions, such as being part of a certain department.',
  syncAliasSectionTitle: 'Alternate emails (alias) sync',
  syncAliasSectionDescription:
    'Choose whether to sync alternate email addresses (aliases). If included, aliases will sync into WiseStamp, allowing you to assign them signatures.',
  syncDisabledSectionTitle: 'Exclude inactive directory users',
  syncWith: 'Sync with WiseStamp',
  doNotSyncWith: "Don't sync with WiseStamp",
  removeEmployee: 'Remove employee from WiseStamp',
  keepEmployee: 'Keep employee on WiseStamp',
  syncDisabledSectionDescription:
    'Choose whether to sync alternate email addresses (aliases) of synced users to WiseStamp. If included, aliases can be assigned their own signatures.',
  syncDailyDoNotSyncOption: "Don't sync daily",
  syncDailySyncOption: 'Sync WiseStamp with {{emailService}} daily',
  chooseConnector: 'Choose connector',
  chooseConnectorAlertDescription:
    'Sync settings will be enabled once you integrate with an email service to sync your directory data.',
  syncAll: 'Sync entire directory',
  syncSpecific: 'Sync specific',
  departmentsDialogTitle: 'Select the -specificatorTypeTitle- you wish to sync',
  refreshListButton: 'Refresh list',
  saveAndSyncButton: 'Save & sync',
  departmentTitle: 'Department',
  orgUnit: 'Organization Unit',
  costCenter: 'Cost Center',
  changeSpecificatorTypeConfirmTitle: 'Only one category is supported for sync',
  changeSpecificatorTypeConfirmButtonTitle: 'Change category',
  changeSpecificatorTypeConfirmQuestion:
    'Changing the category to -category- removes previous selections. Do you want to change your category?',
  removeEmployeesPromtTitle: 'Remove employees?',
  removeEmployeesPromtButton: 'Remove employees',
  removeEmployeePromtQuestion:
    'You have selected to stop syncing employees from -count- departments. Do you also want to permanently remove these employees from WiseStamp?',
  keepButtonTitle: 'Keep',
  removeButtonTitle: 'Remove',
  failedToSaveChanges: 'Failed to save changes. Plase try again',
  noDepartmentsSelected: 'No department selected',
  longActionInProgressTooltip:
    "We're currently retrieving information from your directory to enable you to sync specific employees. This may take some time, based on your directory size. Please try again later.",
  noResultsFound: 'No results found',
  fieldIsRequed: 'Is required.',
  maxLengthMsg: 'Cannot be more than {maxLength} chars',
  canNotContainUrl: 'Cannot contain url.',
  changesWerentSaved:
    "Changes weren't saved. Check for errors in your entries and retry.",
  setDefaultActiveStatusDialogTitle: 'Create employee as Active',
  confirmButton: 'Confirm',
  setDefaultActiveStatusDialogText:
    "New employees who are added to the employee dashboard will automatically get a live email signature. You won't need to individually activate new employees. Before changing this setting, make sure you have a finished signature that's ready to go live for all new employees.",
  setDefaultActiveStatusDialogQuestion:
    'Are you sure you want to change this setting?',
  employeePortalTitleTooltip:
    'The Employee Hub lets your employees review their email signature and ensure their details are accurate. You control what they can edit, if anything, and can share installation instructions when centralized deployment isn’t possible. To grant employees access, send them an invite from the Employees page.',
  employeeEditPermissions: 'Employee edit permissions',
  employeeEditPermissionsDesc:
    'Set whether employees can edit their personal details in the employee hub. You can configure specific editable fields in the employee fields modal. Note that fields fully synced with a directory will always be locked and uneditable.',
  upgrade: 'Upgrade',
  employeeEditPermissionsOptionAll: 'Allow employees edit all details',
  employeeEditPermissionsOptionCustom: 'Allow employees to edit some details',
  employeeEditPermissionsOptionNone: 'Do not allow employees to edit',
  syncFromSecurityGroup: 'Sync From security group',
  securityGroupNotFoundError:
    'The defined Security Group ID does not match any group in your directory, preventing us from syncing. Contact our support with a valid Security Group ID.',
  missingPrivilegesError:
    'WiseStamp no longer has the GroupMember.Read.All permission required to sync users from your Microsoft Security Group. Please grant it again',
  googleWorkspace: 'Google Workspace',
  microsoft: 'Microsoft',
  other: 'Other',
  pleaseSelect: 'Please select',
  on: 'On',
  off: 'Off',
  active: 'Active',
  inactive: 'Inactive',
  syncWisestampDaily: 'Sync WiseStamp daily',
  dontSyncWisestampDaily: "Don't sync daily",
  syncDaily: 'Sync daily',
  dontSyncDaily: "Dont't sync daily",
  notifyEmployee: 'Notify employee',
  dontNotifyEmployee: 'Do not notify employee',
  syncWithWisestamp: 'Sync with WiseStamp',
  dontSyncWithWisestamp: "Don't sync with Wisestamp",
  employeesWithNoSomething: 'Employees with no {{something}}',
  verificationDialogTitle: 'Verify your email address',
  toManyRequests: 'Too many attempts. Please try again later.',
  emailResent: 'Email resent!',
  verificationEmailSentModalSentTo: 'A verification email was sent to',
  verificationEmailSentModalInstructions:
    'Please check your inbox and click the verification link.',
  verificationEmailSentModalTipTitle: 'Haven`t received an email?',
  verificationEmailSentModalResend: 'Resend a verification email',
  verificationEmailSentModalChangeEmail: 'change email address',
  verificationEmailSentModalChangeCheckSpam:
    'Tip: don"t forget to check your spam folder.',
  okButton: 'OK',
  syncInProgressTooltipMessage:
    'Changing this settings is disabled while sync is in progress',
};
