<script setup lang="ts">
import { WsTooltip, WsInput } from '@mfl/common-components';

import strings from '../../../../settings-strings';
const model = defineModel<string>({ required: true });

const props = defineProps<{
  isIntegrated: boolean;
  error?: string;
}>();
</script>
<template>
  <div class="edit-domain-name-control">
    <ws-input
      v-model="model"
      aid="EDIT_DOMAIN_NAME_INPUT"
      :error="props.error"
      size="md"
      :disabled="props.isIntegrated"
      class="edit-domain-input"
    />
    <ws-tooltip
      v-if="props.isIntegrated"
      aid="EDIT_DOMAIN_NAME_BUTTON_TOOLTIP"
      position="top"
    >
      {{ strings.domainNotEditable }}
    </ws-tooltip>
  </div>
</template>
