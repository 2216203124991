<script setup lang="ts">
import BoolSelect from '../../../../components/select/bool-select.vue';
import { BoolSelectOptions } from '../../../../components/select/select-types';

import strings from '../../../../settings-strings';

const model = defineModel<boolean>({ required: true });

const options: BoolSelectOptions = [
  {
    value: 'on',
    label: strings.syncWithWisestamp,
  },
  {
    value: 'off',
    label: strings.dontSyncWithWisestamp,
  },
];
</script>

<template>
  <BoolSelect v-model="model" :options="options" aid="SYNC_ALIAS_SELECT" />
</template>
