import { settingsManager } from '../settings-manager';
import { SpecificatorsWithStatus } from '@msl/settings-gateway-sdk';
import { useRequest } from './use-request';

export enum STATUS {
  FINISHED = 'finished',
}

const specificatorsModel = useRequest<SpecificatorsWithStatus, boolean>(
  (params) => settingsManager.getSpecificators(params),
  {}
);

export default specificatorsModel;
