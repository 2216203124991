import { settingsManager } from '../../settings-manager';
import {
  AnalyticsSettingsModel,
  DomainGoogleAnalyticsValidationErrorsModel,
} from '../../settings-types';
import { useSettingsModel } from '../../composables/use-settings-model';
import { validateGoogleAnalyticsField } from '../../settings-utils';

const domainAnalytics = useSettingsModel<AnalyticsSettingsModel>({
  getDefaultValues() {
    return {
      campaignSource: '',
      campaignMedium: '',
      campaignName: '',
    };
  },

  fetch() {
    return settingsManager.getDomainAnalyticsSettings();
  },

  async update(data) {
    await settingsManager.updateDomainAnalyticsSettings({
      data,
    });
  },

  validate(data) {
    const errors: DomainGoogleAnalyticsValidationErrorsModel = {};
    const campaignSourceError = validateGoogleAnalyticsField(
      data.campaignSource
    );
    const campaignMediumError = validateGoogleAnalyticsField(
      data.campaignMedium
    );
    const campaignNameError = validateGoogleAnalyticsField(data.campaignName);

    if (campaignSourceError) {
      errors.campaignSource = campaignSourceError;
    }
    if (campaignMediumError) {
      errors.campaignMedium = campaignMediumError;
    }
    if (campaignNameError) {
      errors.campaignName = campaignNameError;
    }

    return errors;
  },
});

export default domainAnalytics;
