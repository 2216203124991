<script setup lang="ts" generic="TActionResult = boolean">
import { computed, ref, toValue } from 'vue';
import { useRouter } from 'vue-router';

import SideMenuLink from './side-menu-link.vue';
import SideMenuHeading from './side-menu-heading.vue';

import { MenuItem } from './side-menu-types';

const props = defineProps<{
  heading: string;
  collapsible: boolean;
  aid: string;
  items: Array<MenuItem>;
}>();

const router = useRouter();

const collapsed = ref<boolean>(false);

const expandCollapseIcon = computed(() => {
  const chevron = toValue(collapsed) ? 'fa-chevron-right' : 'fa-chevron-left';
  return `fa-light ${chevron}`;
});

const handleToggleCollapsed = () => {
  collapsed.value = !collapsed.value;
};

const isItemActive = (item: MenuItem) =>
  toValue(router.currentRoute).path === item.route;
</script>
<template>
  <nav
    class="side-menu"
    :class="{ 'side-menu-collapsed': collapsed }"
    :aid="props.aid"
  >
    <ul class="side-menu-items">
      <li class="side-menu-item side-menu-item-heading">
        <SideMenuHeading :text="props.heading" :collapsed="collapsed" />
      </li>
      <li class="side-menu-item side-menu-item-separator">
        <button
          v-if="props.collapsible"
          class="side-menu-button-collapse-expand"
          @click="handleToggleCollapsed"
        >
          <i :class="[expandCollapseIcon]"></i>
        </button>
      </li>
      <li
        v-for="item in items"
        :key="item.aid"
        :aid="item.aid"
        class="side-menu-item"
      >
        <SideMenuLink
          :aid="`${item.aid}-link`"
          :icons="item.icons"
          :collapsed="collapsed"
          :text="item.text"
          :route="item.route"
          :active="isItemActive(item)"
        />
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
:root {
  --side-menu-max-width: 230px;
  --side-menu-collapsed-max-width: 80px;

  --side-menu-item-h-padding: 14px;
  --side-menu-item-v-padding: 10px;

  --transition-duration: 0.3s;

  --side-menu-border-color: var(--Gray-200, #e0e0e0);
}

.side-menu {
  padding: 6px 14px;
  max-width: var(--side-menu-max-width);
  transition: max-width var(--transition-duration) ease-in-out;

  .side-menu-items {
    list-style: none;
    border: none;
    margin: 0;

    display: flex;
    flex-flow: column nowrap;
    gap: 8px;

    .side-menu-item {
      margin: 0;
      padding: 0;

      &.side-menu-item-separator {
        // height: 1px;
        border-bottom: 1px solid var(--side-menu-border-color);
        padding: 0;
        margin: 0 0 10px;
        position: relative;

        .side-menu-button-collapse-expand {
          visibility: hidden;

          position: absolute;
          z-index: 5;
          right: -27px;
          top: -12px;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          line-height: 1em;
          text-indent: -2px;
          background-color: white;
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;

          border: 1px solid #e0e0e0;
          box-shadow:
            0 0 2px rgba(25, 28, 43, 0.04),
            0 4px 8px rgba(107, 108, 126, 0.16);
        }
      }
    }
  }

  &:hover {
    .side-menu-items
      .side-menu-item.side-menu-item-separator
      button.side-menu-button-collapse-expand {
      visibility: visible;
    }
  }

  &.side-menu-collapsed {
    max-width: var(--side-menu-collapsed-max-width);
    // transition: max-width var(--transition-duration) ease-out;
  }
}
</style>
