<script setup lang="ts">
import strings from '../../../settings-strings';
import { computed, DeepReadonly, toValue } from 'vue';
import {
  DomainSyncAndAutomationModel,
  GeneralDomainInfo,
} from '../../../settings-types';
import { WsButton, WsAlert } from '@mfl/common-components';
import { workspaceRoute } from '../../sections';

import WsCard from '../../../components/card/ws-card.vue';
import WsCardSection from '../../../components/card/ws-card-section.vue';
import WsCardSectionSeparator from '../../../components/card/ws-card-section-separator.vue';

import SyncDisabledSelect from './controls/sync-disabled-select.vue';
import SyncAliasSelect from './controls/sync-alias-select.vue';
import DailySyncSelect from './controls/daily-sync-select.vue';
import { navigateTo } from '@mfl/framework';
import SyncTypeSelect from './controls/sync-type-select.vue';
import { IntegrationType } from '@msl/settings-gateway-sdk';

const model = defineModel<DomainSyncAndAutomationModel>({
  required: true,
});

const props = defineProps<{
  generalInfo: DeepReadonly<GeneralDomainInfo>;
}>();

const emit = defineEmits(['update:modelValue']);

const handleUpdateDailyEmployeeSync = (dailyEmployeeSync: boolean) => {
  emit('update:modelValue', {
    ...model.value,
    sync: {
      ...model.value.sync,
      dailyEmployeeSync,
    },
  });
};

const handleUpdateSyncAliases = (syncAliases: boolean) => {
  emit('update:modelValue', {
    ...model.value,
    sync: {
      ...model.value.sync,
      syncAliases,
    },
  });
};

const handleUpdateSyncSuspended = (syncSuspended: boolean) => {
  emit('update:modelValue', {
    ...model.value,
    sync: {
      ...model.value.sync,
      syncSuspended,
    },
  });
};

const hasSelectedDepartments = computed(() => {
  return !!toValue(model).sync?.specificators?.values?.length;
});

const hasSecurityGroup = computed(() => {
  return (
    !!model.value.sync.securityGroupId &&
    props.generalInfo.integrationType === IntegrationType.MICROSOFT
  );
});

const securityGroupName = computed(() => {
  if (
    hasSecurityGroup.value &&
    props.generalInfo.securityGroupName !== 'null'
  ) {
    return props.generalInfo.securityGroupName;
  }
  return '';
});

const syncTypeValue = computed(() => {
  if (toValue(hasSecurityGroup)) {
    return true;
  }

  const v = toValue(model);
  if (!!v.sync?.syncBySpecificators && !!v.sync?.specificators?.type) {
    return true;
  }

  if (props.generalInfo.requireFinishIntegration) {
    return undefined;
  }

  return false;
});

const chooseConnectorHandler = () => {
  navigateTo(workspaceRoute);
};
</script>

<template>
  <ws-card v-if="props.generalInfo.isIntegrated">
    <ws-card-section>
      <template #title>{{ strings.syncDailySectionTitle }}</template>
      <template #desc>{{ strings.syncDailySectiondescription }}</template>
      <template #control>
        <daily-sync-select
          v-model="model.sync.dailyEmployeeSync"
          @update:model-value="handleUpdateDailyEmployeeSync"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{ strings.syncTypeSectionTitle }}</template>
      <template #desc>{{ strings.syncTypeSectionDescription }}</template>
      <template #control>
        <sync-type-select
          v-model="syncTypeValue"
          :security-group-name="securityGroupName"
          :has-security-group="hasSecurityGroup"
          :has-selected-departments="hasSelectedDepartments"
          :integration-error="props.generalInfo.integrationError"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{ strings.syncAliasSectionTitle }}</template>
      <template #desc>{{ strings.syncAliasSectionDescription }}</template>
      <template #control>
        <sync-alias-select
          v-model="model.sync.syncAliases"
          @update:model-value="handleUpdateSyncAliases"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{ strings.syncDisabledSectionTitle }}</template>
      <template #desc>{{ strings.syncDisabledSectionDescription }}</template>
      <template #control>
        <sync-disabled-select
          v-model="model.sync.syncSuspended"
          @update:model-value="handleUpdateSyncSuspended"
        />
      </template>
    </ws-card-section>
  </ws-card>
  <div v-else class="alert-wrapper">
    <ws-alert
      type="info"
      variant="outlined"
      :closable="false"
      :block-actions="false"
      :open="true"
      aid="CHOOSE_CONNECTOR_ALERT"
    >
      {{ strings.chooseConnectorAlertDescription }}
      <template #actions>
        <ws-button
          aid="CHOOSE_CONNECTOR_BUTTON"
          :label="strings.chooseConnector"
          size="sm"
          variant="text"
          @click="chooseConnectorHandler()"
        />
      </template>
    </ws-alert>
  </div>
</template>
<style scoped>
.alert-wrapper {
  max-width: 900px;
  padding-bottom: 14px;
}
</style>
