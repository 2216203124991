<script setup lang="ts">
import { onMounted } from 'vue';
import { PreviewInviteEmailDialogInput } from '../../../settings-types';
import strings from '../../../settings-strings';
import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';

import vLoading from '../../../directives/loading';
import { settingsManager } from '../../../settings-manager';
import { useRequest } from '../../../composables/use-request';

const { dialog } = defineProps<{
  dialog: IDialog<PreviewInviteEmailDialogInput>;
}>();

const emailPreview = useRequest(async () => {
  return settingsManager.getInviteEmailPreview();
}, '');

onMounted(() => {
  emailPreview.triggerFetch();
});
</script>
<template>
  <ws-dialog-section
    v-loading="emailPreview.isLoading"
    class="invite-email-preview text-md"
  >
    <div class="preview-html-content-container">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="preview-html-content"
        aid="PREVIEW_EMAIL_HTML_CONTENT"
        v-html="emailPreview.data"
      ></div>
      <!--eslint-enable-->
    </div>
  </ws-dialog-section>
  <ws-dialog-actions>
    <ws-button
      :label="strings.employeePortalInviteEmailPreviewDialogButton"
      aid="INVITE_EMAIL_PREVIEW_DIALOG_BUTTON"
      color="primary"
      @click="dialog.close()"
    />
  </ws-dialog-actions>
</template>
<style scoped>
.invite-email-preview {
  margin: 14px 24px;
  padding: 24px;

  background: var(--Primary-50, #f4f6f8);
  color: var(--Gray-500, #2d2e30);
  box-sizing: border-box;
  max-height: 343px;
  overflow: auto;

  width: 512px;
  height: 343px;
}
</style>
