<script setup lang="ts" generic="TModel extends object | string | number">
import { computed, ref, toValue } from 'vue';
import strings from '../../settings-strings';

import {
  WsSelect,
  WsSelectHeader,
  WsSelectTrigger,
  WsSelectPanel,
  WsSelectSearchInput,
  WsSelectOptions,
  WsSelectOption,
} from '@mfl/common-components';
import { createDebouncedHandler } from '../../settings-utils';
import { GenericSelectOption, SelectOptionsGroup } from './select-types';

const props = withDefaults(
  defineProps<{
    aid: string;
    options?: Array<GenericSelectOption<TModel>>;
    groups?: Array<SelectOptionsGroup<TModel>>;
    valueToKeyFn?: (v: TModel) => string;
  }>(),
  {
    valueToKeyFn: (v: TModel) => `${v}`,
  }
);

const { valueToKeyFn } = props;

type LocalOption<TModel> = {
  text: string;
  key: string;
  value: TModel;
};

type LocalGroup<TModel> = {
  name: string;
  options: Array<LocalOption<TModel>>;
};

const mapOptions = (
  options: Array<GenericSelectOption<TModel>>
): Array<LocalOption<TModel>> => {
  return options.map((opt) => {
    return {
      text: opt.text,
      value: opt.value,
      key: valueToKeyFn(opt.value),
    };
  });
};

const localOptions = computed<Array<LocalOption<TModel>>>(() => {
  if (!props.options) {
    return [];
  }
  return mapOptions(props.options);
});

const localGroups = computed<Array<LocalGroup<TModel>>>(() => {
  if (!props.groups) {
    return [];
  }

  return props.groups.map((g) => {
    return {
      name: g.name,
      options: mapOptions(g.options),
    };
  });
});

const query = ref<string>('');

const handleQueryChange = createDebouncedHandler((q: string) => {
  query.value = q;
});

const filterOptions = <TModel,>(
  opts: Array<LocalOption<TModel>>,
  q: string
) => {
  if (opts.length <= 0) {
    return [];
  }

  if (q.length <= 2) {
    return opts;
  }

  return opts.filter(({ key, text }) => {
    if (text.toLowerCase().indexOf(q) >= 0) {
      return true;
    }

    // TODO: this may be changed/customized later
    return key.toLowerCase().indexOf(q) >= 0;
  });
};

const localFilteredOptions = computed<Array<LocalOption<TModel>>>(() => {
  return filterOptions(toValue(localOptions), toValue(query).toLowerCase());
});

const localFilteredGroups = computed<Array<LocalGroup<TModel>>>(() => {
  const groups = toValue(localGroups);
  if (groups.length <= 0) {
    return [];
  }

  const q = toValue(query).toLowerCase();

  return groups.reduce(
    (acc, g) => {
      const filteredGroupOptions = filterOptions(g.options, q);
      if (filteredGroupOptions.length > 0) {
        acc.push({
          name: g.name,
          options: filteredGroupOptions,
        });
      }
      return acc;
    },
    [] as Array<LocalGroup<TModel>>
  );
});

const labelsMap = computed<Record<string, string>>(() => {
  return localOptions.value.reduce(
    (acc, opt) => {
      acc[opt.key] = opt.text;
      return acc;
    },
    { '': strings.select } as Record<string, string>
  );
});

const model = defineModel<TModel>({
  required: true,
});

const handleUpdateModelValue = (v: unknown) => {
  model.value = v as TModel;
};
</script>
<template>
  <WsSelect
    :aid="props.aid"
    :model-value="model"
    :option-key="valueToKeyFn"
    :option-label="(v) => labelsMap[valueToKeyFn(v)]"
    advanced
    @update:model-value="handleUpdateModelValue"
  >
    <WsSelectTrigger />

    <WsSelectPanel>
      <WsSelectSearchInput
        :model-value="query"
        @update:model-value="handleQueryChange"
      />
      <template v-if="localFilteredGroups.length > 0">
        <template v-for="group in localFilteredGroups" :key="group.name">
          <WsSelectHeader>{{ group.name }}</WsSelectHeader>
          <WsSelectOption
            v-for="o in group.options"
            :key="o.key"
            :value="o.value"
          >
            {{ o.text }}
          </WsSelectOption>
        </template>
      </template>
      <template v-else-if="localFilteredOptions.length > 0">
        <WsSelectOptions>
          <template v-if="!!localFilteredOptions.length">
            <WsSelectOption
              v-for="opt in localFilteredOptions"
              :key="opt.key"
              :value="opt.value"
            >
              {{ opt.text }}
            </WsSelectOption>
          </template>
        </WsSelectOptions>
      </template>
      <WsSelectOption v-else disabled>
        {{ strings.noResultsFound }}
      </WsSelectOption>
    </WsSelectPanel>
  </WsSelect>
</template>
<style lang="scss"></style>
