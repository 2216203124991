<script setup lang="ts">
import { currentUser } from '@mfl/platform-shell';
import { UserEntitlements } from '../../../../settings-types';
import { getWebAppUrl } from '../../../../settings-utils';

import strings from '../../../../settings-strings';
import { BoolSelectUpgradabeOptions } from '../../../../components/select/select-types';
import BoolSelect from '../../../../components/select/bool-select.vue';
import { computed, toValue } from 'vue';

const model = defineModel<boolean>({ required: true });

const upgradePlanRequired = computed<boolean>(
  () => !currentUser.isEntitledTo(UserEntitlements.SETTINGS_AUTOMATIONS)
);

const options: BoolSelectUpgradabeOptions = [
  {
    value: 'off',
    label: strings.keepEmployee,
  },
  {
    value: 'on',
    label: strings.removeEmployee,
    requiresUpgrade: toValue(upgradePlanRequired),
  },
];

const handleUpgradePlan = () => {
  if (toValue(upgradePlanRequired)) {
    window.open(getWebAppUrl() + '#upgrade', '_blank');
  }
};
</script>

<template>
  <BoolSelect
    v-model="model"
    aid="AUTO_REMOVE_SELECT"
    :options="options"
    @upgrade-plan="handleUpgradePlan"
  />
</template>
