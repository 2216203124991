import { Component } from 'vue';
import { createWebHistory, createRouter } from 'vue-router';

import { baseRoute } from '../constants';
import settingsStrings from '../settings-strings';

import {
  DomainSection,
  AnalyticsSection,
  SyncAndAutomationSection,
  EmployeePortalSection,
} from './index';

export type SettingSection = {
  aid: string;
  text: string;
  //[icon, hover/active icon?]
  icons: Array<string>;
  route: string;
  component: Component;
  action?: () => Promise<boolean>;
  checkPermission?: () => boolean;
};

export const workspaceRoute = `${baseRoute}/domain`;
export const syncAndAutomationsRoute = `${baseRoute}/sync-and-automation`;
export const employeePortalRoute = `${baseRoute}/employee-portal`;
export const analyticsRoute = `${baseRoute}/analytics`;

/**
 * List of all settings pages\
 * Used for building the router and the navigation bar
 */
export const sections: SettingSection[] = [
  {
    text: settingsStrings.workspace,
    icons: ['fa-light fa-building', 'fa-regular fa-building'],
    aid: 'domain-nav-item',
    route: workspaceRoute,
    component: DomainSection,
  },
  {
    text: settingsStrings.syncAndAutomations,
    icons: ['fa-light fa-sync', 'fa-regular fa-sync'],
    aid: 'sync-and-automation-nav-item',
    route: syncAndAutomationsRoute,
    component: SyncAndAutomationSection,
  },
  {
    text: settingsStrings.employeePortal,
    icons: ['fa-light fa-user', 'fa-regular fa-user'],
    aid: 'employee-portal-nav-item',
    route: employeePortalRoute,
    component: EmployeePortalSection,
  },
  {
    text: settingsStrings.analytics,
    icons: ['fa-light fa-analytics', 'fa-regular fa-analytics'],
    aid: 'analytics-nav-item',
    route: analyticsRoute,
    component: AnalyticsSection,
  },
];

/**
 * create a vue router with routes according to the current user permissions\
 * used for both runtime and tests
 * @returns a vue-router
 */
export function createSettingsRouter() {
  const router = createRouter({
    history: createWebHistory(),
    routes: sections
      .filter((s) => !s.checkPermission || s.checkPermission())
      .map(({ route, component }) => ({
        path: route,
        component,
      })),
  });

  return router;
}
