<script setup lang="ts"></script>
<template>
  <div class="ws-card-section">
    <div class="ws-card-section-title">
      <slot name="title" />
    </div>
    <div class="ws-card-section-body">
      <div class="ws-card-section-desc">
        <slot name="desc" />
      </div>
      <div class="ws-card-section-control">
        <slot name="control" />
      </div>
    </div>
  </div>
</template>
<style lang="scss"></style>
