import { openDialog } from '@mfl/common-components';
import setDefaultActiveStatusDialog from './set-default-active-status-dialog.vue';

import strings from '../../../../settings-strings';

export type setDefaultActiveStatusDialogInput = void;

export type setDefaultActiveStatusDialogOutput = boolean;

export const openSetDefaultActiveStatusDialog = async (
  input: setDefaultActiveStatusDialogInput
): Promise<setDefaultActiveStatusDialogOutput> => {
  return (
    (await openDialog<
      setDefaultActiveStatusDialogInput,
      setDefaultActiveStatusDialogOutput
    >({
      aid: 'SET_DEFAULT_ACTIVE_STATUS_DIALOG',
      headerText: strings.setDefaultActiveStatusDialogTitle,
      component: setDefaultActiveStatusDialog,
      input,
    })) || false
  );
};
