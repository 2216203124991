import { openDialog } from '@mfl/common-components';
import DepartmentsDialog from './departments-dialog.vue';

import strings from '../../../../settings-strings';
import { FilteredListItem } from '../../../../components/filtered-list/filtered-list-types';
import { Specificator } from '@msl/settings-gateway-sdk';

export type DepartmentsDialogInput = {
  specificatorTypeTitle: string;
};

export type InviteEmailPreviewDialogOutput = void;

export type DepartmentsDialogOption = FilteredListItem<
  Specificator & {
    isOrgUnit?: true;
    isNone?: true;
    title?: string;
  }
>;

export type PathPart = {
  text: string;
  dataValue?: string;
  small?: boolean;
};

export const openDepartmentsDialog = async (
  input: DepartmentsDialogInput
): Promise<InviteEmailPreviewDialogOutput> => {
  await openDialog<DepartmentsDialogInput, InviteEmailPreviewDialogOutput>({
    aid: 'DEPARTMENTS_DIALOG',
    headerText: strings.departmentsDialogTitle.replace(
      '-specificatorTypeTitle-',
      input.specificatorTypeTitle
    ),
    component: DepartmentsDialog,
    input,
  });
};
