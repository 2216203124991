<script setup lang="ts">
import strings from '../../../../settings-strings';
import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';
import { getWebAppUrl } from '../../../../settings-utils';
import { toast, ToastStatus } from '@mfl/common-components';
import emailVerificationModel from '../../../../composables/send-verification-email-model';
import getInvitationTrustPolicyModel from '../../../../composables/invitation-trust-policy-model';
import { setDefaultActiveStatusDialogInput } from '../set-default-active-status-dialog/set-default-active-status-dialog';

const { dialog } = defineProps<{
  dialog: IDialog<setDefaultActiveStatusDialogInput>;
}>();

async function resendVerificationEmail() {
  try {
    await emailVerificationModel.triggerFetch();
    toast({
      status: ToastStatus.Completed,
      message: strings.emailResent,
      aid: 'VERIFICATION_EMAIL_RESENT_TOAST',
    });
  } catch (_e) {
    toast({
      status: ToastStatus.Error,
      message: strings.toManyRequests,
      aid: 'VERIFICATION_EMAIL_ERROR_TOAST',
    });
  }
}

async function okHandler() {
  await getInvitationTrustPolicyModel.triggerFetch();
  dialog.close();
}

function openProfilePanel() {
  dialog.close();
  window.open(getWebAppUrl('/profile'), '_blank');
}
</script>
<template>
  <ws-dialog-section>
    <div class="wrapper">
      <div>
        {{ strings.verificationEmailSentModalSentTo }}
        <span class="user-email">user@email.com</span>.
        {{ strings.verificationEmailSentModalInstructions }}
      </div>
      <div class="tip">
        <div class="tip-title">
          {{ strings.verificationEmailSentModalTipTitle }}
        </div>
        <div class="tip-text">
          <span class="tip-btn" @click="resendVerificationEmail">
            {{ strings.verificationEmailSentModalResend }}
          </span>
          or
          <span class="tip-btn" @click="openProfilePanel">
            {{ strings.verificationEmailSentModalChangeEmail }}
          </span>
          <br />
          {{ strings.verificationEmailSentModalChangeCheckSpam }}
        </div>
      </div>
    </div>
  </ws-dialog-section>
  <ws-dialog-actions>
    <ws-button
      :label="strings.okButton"
      aid="VERIFICATION_DIALOG_OK_BUTTON"
      color="primary"
      @click="okHandler()"
    />
  </ws-dialog-actions>
</template>
<style lang="scss" scoped>
.wrapper {
  padding: 14px 24px 24px 24px;
  color: #2d2e30;
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;

  .tip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    margin-top: 14px;

    &-title {
      color: #2d2e30;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
    }

    &-text {
      font-size: 13px;
      font-weight: 600;
    }

    &-btn {
      color: #2d2e30;
      font-family: Mulish;
      font-size: 13px;
      font-weight: 500;
      line-height: 19px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      cursor: pointer;
    }
  }
}
</style>
