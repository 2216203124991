import { settingsManager } from '../../settings-manager';
import {
  EmployeePortalSettingsErrorsModel,
  EmployeePortalSettingsModel,
} from '../../settings-types';
import { useSettingsModel } from '../../composables/use-settings-model';
import {
  validateEmailSubject,
  validateInviteSender,
} from '../../settings-utils';
import { EmployeeEditPermissions } from '@msl/settings-gateway-sdk';

const employeePortalSettings = useSettingsModel<EmployeePortalSettingsModel>({
  getDefaultValues() {
    return {
      inviteSender: '',
      emailSubject: '',
      instructions: [],
      employeeEditPermissions: EmployeeEditPermissions.ALL,
    };
  },

  fetch() {
    return settingsManager.getEmployeePortalSettings();
  },

  async update(data) {
    await settingsManager.updateEmployeePortalSettings(data);
  },

  validate(data) {
    const errors: EmployeePortalSettingsErrorsModel = {};
    const inviteSenderMsg = validateInviteSender(data.inviteSender);
    if (inviteSenderMsg) {
      errors.inviteSender = inviteSenderMsg;
    }

    const emailSubjectMsg = validateEmailSubject(data.emailSubject);
    if (emailSubjectMsg) {
      errors.emailSubject = emailSubjectMsg;
    }
    return errors;
  },
});

export default employeePortalSettings;
