<script setup lang="ts">
import { WsInput } from '@mfl/common-components';

import strings from '../../../../settings-strings';

const props = defineProps<{
  errors: {
    inviteSender?: string;
    emailSubject?: string;
  };
}>();

const emailSubject = defineModel<string>('emailSubject', {
  required: true,
});

const inviteSender = defineModel<string>('inviteSender', {
  required: true,
});
</script>
<template>
  <div class="invite-email-form">
    <div class="settings-input-container">
      <label class="invite-email-label text-sm" for="email-subject-input">
        {{ strings.employeePortalInviteEmailSubjectLineLabel }}
      </label>
      <WsInput
        id="email-subject-input"
        v-model="emailSubject"
        aid="SETTINGS_EMAIL_SUBJECT"
        size="md"
        :error="props.errors.emailSubject"
      />
    </div>
    <div class="settings-input-container">
      <label class="invite-email-label text-sm" for="invite-sender-input">
        {{ strings.employeePortalInviteEmailSenderNameLabel }}
      </label>
      <WsInput
        id="invite-sender-input"
        v-model="inviteSender"
        aid="SETTINGS_EMAIL_SENDER"
        :error="props.errors.inviteSender"
        size="md"
      />
    </div>
  </div>
</template>
