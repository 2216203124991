<script setup lang="ts">
import { computed, DeepReadonly } from 'vue';

import SelectWithSearch from '../../../../components/select/select-with-search.vue';
import { GenericSelectOption } from '../../../../components/select/select-types';
import { EmployeeGroup } from '@msl/employee-group-gateway-sdk';

const model = defineModel<string>({ required: true });

const props = defineProps<{
  groupsList: DeepReadonly<Array<EmployeeGroup>>;
}>();

const selectGroups = computed<Array<GenericSelectOption<string>>>(() => {
  return props.groupsList.map((g) => {
    return {
      text: g.name || '',
      value: g.groupId || '',
    };
  });
});
</script>

<template>
  <SelectWithSearch
    v-model="model"
    :options="selectGroups"
    aid="DEFAULT_GROUP_SELECT"
  />
</template>
