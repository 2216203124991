<script setup lang="ts">
import strings from '../../../../settings-strings';
import { RemoveIntegrationDialogInput } from './remove-integration-dialog';
import { ref } from 'vue';
import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';

import domainBasicInfo from '../../domain-basic-info';
import generalDomainInfo from '../../../../composables/domain-general-info';

const { removeDomainIntegration, triggerFetch } = domainBasicInfo;

async function removeIntegrationHandler() {
  loading.value = true;
  try {
    await removeDomainIntegration();
    await generalDomainInfo.triggerFetch();
    await triggerFetch();
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
    dialog.close();
  }
}

const loading = ref(false);

const { dialog } = defineProps<{
  dialog: IDialog<RemoveIntegrationDialogInput>;
}>();
</script>
<template>
  <ws-dialog-section>
    <p class="remove-integration-dialog-text">
      {{ strings.confirmRemoveIntegrationPromptText }}<br /><br />
    </p>
    <p class="remove-integration-dialog-text">
      {{ strings.confirmRemoveIntegrationPromptQuestion }}
    </p>
  </ws-dialog-section>
  <ws-dialog-actions>
    <ws-button
      :label="strings.confirmRemoveIntegrationPromptCancelButton"
      aid="CANCEL_BUTTON"
      variant="outlined"
      color="gray-500"
      @click="dialog.close()"
    />
    <ws-button
      :label="strings.confirmRemoveIntegrationPromptOkButton"
      aid="REMOVE_INTEGRATION_BUTTON"
      color="danger"
      :disabled="loading"
      @click="removeIntegrationHandler()"
    />
  </ws-dialog-actions>
</template>
<style scoped>
.remove-integration-dialog-text {
  color: #2d2e30;
  font-family: Mulish;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  max-width: 352px;
}
</style>
