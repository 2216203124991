<script setup lang="ts" generic="TValue extends string | number">
import { WsSelectOption, WsBadge } from '@mfl/common-components';
import strings from '../../settings-strings';

const props = withDefaults(
  defineProps<{
    aid: string;
    value: TValue;
    label: string;
    upgradeRequired?: boolean;
  }>(),
  {
    upgradeRequired: false,
  }
);
</script>
<template>
  <WsSelectOption :value="props.value">
    {{ props.label }}
    <template v-if="props.upgradeRequired" #append>
      <WsBadge
        :label="strings.upgrade"
        icon="fa-regular fa-circle-arrow-up"
        :aid="props.aid"
        class="text-uppercase shrink-0"
      />
    </template>
  </WsSelectOption>
</template>
