<script setup lang="ts">
import strings from '../../../../settings-strings';
import { setDefaultActiveStatusDialogInput } from './set-default-active-status-dialog';
import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';

import domainSyncAutomation from '../../../../composables/domain-sync-automation';

const { model } = domainSyncAutomation;

function confirmHandler() {
  model.value.newEmployees.autoActivate = true;
  dialog.close(true);
}

const { dialog } = defineProps<{
  dialog: IDialog<setDefaultActiveStatusDialogInput>;
}>();
</script>
<template>
  <ws-dialog-section>
    <p class="set-default-active-status-dialog-text">
      {{ strings.setDefaultActiveStatusDialogText }}<br /><br />
    </p>
    <p class="set-default-active-status-dialog-text">
      {{ strings.setDefaultActiveStatusDialogQuestion }}
    </p>
  </ws-dialog-section>
  <ws-dialog-actions>
    <ws-button
      :label="strings.EditDomainNameDialogCancelButton"
      aid="SET_DEFAULT_ACTIVE_STATUS_DIALOG_CANCEL_BUTTON"
      variant="outlined"
      color="gray-500"
      @click="dialog.close()"
    />
    <ws-button
      :label="strings.confirmButton"
      aid="SET_DEFAULT_ACTIVE_STATUS_DIALOG_CONFIRM_BUTTON"
      color="primary"
      @click="confirmHandler()"
    />
  </ws-dialog-actions>
</template>
<style scoped>
.set-default-active-status-dialog-text {
  color: #2d2e30;
  font-family: Mulish;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  max-width: 352px;
}
</style>
