<script setup lang="ts">
import { WsCheckbox } from '@mfl/common-components';

const model = defineModel<Array<string>>({ required: true });

const instructionsCheckboxes = [
  {
    value: 'Mac Mail',
    text: 'Mac Mail',
    aid: 'MAC_MAIL',
  },
  {
    value: 'iPhone',
    text: 'iOS Mail (iPhone/iPad)',
    aid: 'IPHONE',
  },
  {
    value: 'Gmail',
    text: 'Personal Gmail',
    explanation: 'gmail.com addresses',
    aid: 'GMAIL',
  },
  {
    value: 'Outlook desktop',
    text: 'Outlook',
    // explanation: 'only when centralized deployment is not possible',
    aid: 'OUTLOOK',
  },
  {
    value: 'HTML',
    text: 'Other',
    aid: 'HTML',
  },
];
</script>
<template>
  <div class="deployment-instructions">
    <ul class="instructions-list">
      <li
        v-for="item in instructionsCheckboxes"
        :key="`instructions-${item.aid}`"
        class="instruction-item"
      >
        <ws-checkbox
          v-model="model"
          :aid="item.aid"
          :value="item.value"
          :label="item.text"
        />
        <span v-if="item.explanation" class="instruction-desc">
          {{ item.explanation }}
        </span>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.deployment-instructions {
  .instructions-list {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .instruction-item {
      display: flex;
      flex-direction: row;
      gap: 6px;

      .instruction-desc {
        color: var(--Gray-400, #828282);

        font-family: Mulish;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
</style>
