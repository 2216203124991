<script setup lang="ts">
import { computed, toValue } from 'vue';

import strings from '../../settings-strings';
import SelectOptionWithUpgradable from './select-option-with-upgradable.vue';

import { WsSelect } from '@mfl/common-components';
import {
  OnOffOption,
  OnOffValue,
  UpgradableSelectOption,
} from './select-types';

const model = defineModel<boolean | undefined>({ required: true });

const emit = defineEmits(['upgrade-plan']);

type Option = OnOffOption & UpgradableSelectOption;

const props = defineProps<{
  aid: string;
  options: [Option, Option];
  disabled?: boolean;
}>();

const labels = computed(() =>
  props.options.reduce(
    (acc, opt) => {
      const defaultLabel = opt.value === 'on' ? strings.on : strings.off;
      acc[opt.value] = opt.label || defaultLabel;
      return acc;
    },
    {} as Record<OnOffValue, string>
  )
);

const localValue = computed<OnOffValue>({
  get() {
    if (toValue(model) !== undefined) {
      return toValue(model) ? 'on' : 'off';
    }
    return '';
  },
  set(v: OnOffValue) {
    model.value = v === 'on';
  },
});

const handleUpdateModelValue = (v: unknown) => {
  if (v !== 'on' && v !== 'off') {
    return console.warn(
      'bool-select.handleUpdateModelValue: invalid select value detected: ',
      v
    );
  }

  const selected = props.options.find((o) => o.value === v);
  if (!selected) {
    return console.warn(
      'bool-select.handleUpdateModelValue: invalid select value detected: ',
      v
    );
  }

  if (selected.requiresUpgrade) {
    emit('upgrade-plan');
  } else {
    localValue.value = v;
  }
};
</script>

<template>
  <WsSelect
    :model-value="localValue"
    :option-label="(v) => toValue(labels)[v] || strings.pleaseSelect"
    :option-key="(v) => v"
    :aid="props.aid"
    :disabled="props.disabled"
    @update:model-value="handleUpdateModelValue"
  >
    <SelectOptionWithUpgradable
      v-for="o in options"
      :key="o.value"
      :value="o.value"
      :upgrade-required="o.requiresUpgrade"
      :label="o.label"
      :aid="props.aid + '_' + `${o.value}`"
    />
  </WsSelect>
</template>
