<script setup lang="ts">
import BoolSelect from '../../../../components/select/bool-select.vue';

import strings from '../../../../settings-strings';
import { BoolSelectOptions } from '../../../../components/select/select-types';

const model = defineModel<boolean>({ required: true });

const options: BoolSelectOptions = [
  {
    value: 'on',
    label: strings.syncWisestampDaily,
  },
  {
    value: 'off',
    label: strings.dontSyncDaily,
  },
];
</script>

<template>
  <BoolSelect v-model="model" :options="options" aid="DAILY_SYNC_SELECT" />
</template>
