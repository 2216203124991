<script setup lang="ts" generic="TData">
import { computed, ref, shallowRef, toValue, watch } from 'vue';
import type { Component, UnwrapRef } from 'vue';
import { WsInput, WsToggle, WsCheckbox } from '@mfl/common-components';
import SortButton from './sort-button.vue';
import {
  SelectAllState,
  FilteredListItem,
  SortDirection,
} from './filtered-list-types';
import { createDebouncedHandler } from '../../settings-utils';

const props = defineProps<{
  aid: string;
  items: Array<FilteredListItem<TData>>;
  itemComponent: Component;
}>();

const emit = defineEmits(['selection-change']);

const searchQuery = ref<string>('');

const handleSearchQueryChange = createDebouncedHandler((s: string) => {
  searchQuery.value = s;
});

const localItems = shallowRef<Array<FilteredListItem<TData>>>([]);

const showOnlySelectedItems = ref<boolean>(false);

const sortDirection = ref<SortDirection>(SortDirection.Asc);

const selectAll = ref<SelectAllState>(getSelectAllState(localItems.value));

watch(
  () => props.items,
  (newItems) => {
    localItems.value = newItems;
    selectAll.value = getSelectAllState(newItems);
  },
  { immediate: true, deep: true }
);

const filteredAndSortedItems = computed<Array<FilteredListItem<TData>>>(() => {
  const items = toValue(localItems);
  const onlySelected = toValue(showOnlySelectedItems);
  const q = toValue(searchQuery);
  const sortDir = toValue(sortDirection);

  const specialItems: Array<FilteredListItem<TData>> = [];

  const filteredItems = items.filter((item) => {
    if (onlySelected && !item.selected) {
      return false;
    }

    const match = filterItemBySearchQuery(item, q);

    if (!match) {
      return false;
    }

    if (item.special === true) {
      specialItems.push(item);
      return false;
    }

    return true;
  });

  if (sortDir !== SortDirection.None) {
    const sorterFn =
      sortDir === SortDirection.Asc ? itemSorterAsc : itemSorterDesc;
    filteredItems.sort(sorterFn);
    specialItems.sort(sorterFn);
  }

  return [...specialItems, ...filteredItems];
});

function filterItemBySearchQuery(item: FilteredListItem<TData>, q: string) {
  // no need to filter items with 2 symbol query
  if (q.length <= 2) {
    return true;
  }

  return item.searchKey.toLowerCase().includes(q.toLowerCase());
}

function itemSorterAsc<T extends { searchKey: string }>(a: T, b: T) {
  if (a.searchKey === b.searchKey) {
    return 0;
  }
  return a.searchKey > b.searchKey ? 1 : -1;
}

function itemSorterDesc<T extends { searchKey: string }>(a: T, b: T) {
  if (a.searchKey === b.searchKey) {
    return 0;
  }
  return a.searchKey > b.searchKey ? -1 : 1;
}

function countSelectedItems(
  items: Array<FilteredListItem<TData | UnwrapRef<TData>>>
) {
  return items.reduce((count, item) => (item.selected ? count + 1 : count), 0);
}

function getSelectAllState(
  items: Array<FilteredListItem<TData | UnwrapRef<TData>>>
) {
  const selectedItemsCount = countSelectedItems(items);
  switch (true) {
    case selectedItemsCount > 0 && selectedItemsCount >= items.length:
      return SelectAllState.All;
    case selectedItemsCount > 0 && selectedItemsCount < items.length:
      return SelectAllState.Some;
    default:
      return SelectAllState.None;
  }
}

const getSelectedItemsData = (allSelected?: boolean) => {
  if (allSelected === false) {
    return [];
  }

  let items = toValue(localItems);
  if (allSelected === undefined) items = items.filter((i) => i.selected);
  return items.map((i) => i.data);
};

const handleSelectAllChange = (value: unknown) => {
  const selected = !!value;
  selectAll.value = selected ? SelectAllState.All : SelectAllState.None;
  localItems.value = localItems.value.map((item) => {
    return {
      ...item,
      selected,
    };
  });

  emit('selection-change', selected ? getSelectedItemsData() : []);
};

const handleItemSelect = (
  item: FilteredListItem<TData | UnwrapRef<TData>>,
  v: unknown
) => {
  const selected = !!v;
  localItems.value = localItems.value.map((localItem) => {
    if (localItem.searchKey === item.searchKey) {
      return {
        ...localItem,
        selected: selected,
      };
    }
    return localItem;
  });
  selectAll.value = getSelectAllState(toValue(localItems));
  emit('selection-change', getSelectedItemsData());
};
</script>

<template>
  <div class="filtered-list">
    <ws-input
      :model-value="searchQuery"
      placeholder="Search"
      size="md"
      aid="MANAGE_LIST_SEARCH_INPUT"
      @update:model-value="handleSearchQueryChange"
    >
      <template #prepend>
        <span aria-hidden="true" class="fa-regular fa-search text-lg" />
      </template>
    </ws-input>
    <div class="header-wrapper">
      <div class="left-wrapper">
        <ws-checkbox
          :model-value="selectAll === SelectAllState.All"
          :indeterminate="selectAll === SelectAllState.Some"
          aid="CHECKBOX_SELECT_ALL"
          @update:model-value="handleSelectAllChange"
        />
        <span class="select-all-title">Select all</span>
      </div>
      <div class="right-wrapper">
        <span class="switch-title">Selected</span>
        <ws-toggle v-model="showOnlySelectedItems" aid="SHOW_SELECTED_TOGGLE" />
        <SortButton
          v-model="sortDirection"
          class="sort-btn"
          aid="SORT_BUTTON"
        />
      </div>
    </div>
    <div class="content-wrapper">
      <ul v-if="filteredAndSortedItems.length" class="list-wrapper">
        <li
          v-for="item in filteredAndSortedItems"
          :key="item.aid"
          class="list-item"
        >
          <ws-checkbox
            :model-value="item.selected"
            :aid="'CHECKBOX_SPECIFICATOR_VALUE' + item.aid"
            label=""
            @update:model-value="handleItemSelect(item, $event)"
          />
          <component :is="props.itemComponent" v-bind="item" />
        </li>
      </ul>
      <p v-else class="no-results">No results were found</p>
    </div>
  </div>
</template>

<style scoped>
.filtered-list {
  width: 510px;

  .header-wrapper {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
    margin-bottom: 14px;

    .left-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .select-all-title {
        color: var(--ws-color-dark, #191c2b);
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
      }
    }

    .right-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 6px;

      .switch-title {
        color: var(--ws-color-dark, #191c2b);
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
      }

      .sort-btn {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .content-wrapper {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--ws-dark-gray);
    }

    ::-webkit-scrollbar-track {
      background-color: #ffffff;
    }

    .list-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      gap: 14px;
      margin-bottom: 14px;

      .list-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        width: 100%;

        .list-item-title {
          color: var(--ws-color-dark, #191c2b);
          font-family: Mulish;
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          padding: 0;
        }
      }
    }

    .no-results {
      color: var(--ws-color-dark, #191c2b);
      font-family: Mulish;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
